import { Button, Dropdown, Modal, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { HiPhoneMissedCall } from 'react-icons/hi';
import { SiCalendly } from 'react-icons/si';
import { VscCallOutgoing } from 'react-icons/vsc';
import { Link, useSearchParams } from 'react-router-dom';
import axios from '../../../config/axios';
import { useThemeStore } from '../../../store/theme';
import { useToolsStore } from '../../../store/tools';
import { allTools } from '../data/data';

type Option = {
	key: string;
	icon: JSX.Element;
	label: string;
};

type ToolsProps = {
	for: 'assistant' | 'state';
};

const getUserOptions = (app_name: string) => {
	const genericOptions = [
		{
			key: 'end_call',
			icon: <HiPhoneMissedCall size={20} />,
			label: 'End Call',
		},
		{
			key: 'transfer_call',
			icon: <VscCallOutgoing size={20} />,
			label: 'Call Transfer',
		},
	];

	if (app_name === 'calendly') {
		const calendlyOptions = [
			{
				key: 'calendly_create_calendar_event',
				icon: <SiCalendly size={20} />,
				label: 'Book on Calendar (Calendly)',
			},
			{
				key: 'calendly_get_user_busy_times',
				icon: <SiCalendly size={20} />,
				label: 'Check Calendar Availability (Calendly)',
			},
		];
		return [...genericOptions, ...calendlyOptions];
	}

	return genericOptions;
};

const Tools: FC<ToolsProps> = (props) => {
	const { is_dark_mode } = useThemeStore();
	const { tools, addTool } = useToolsStore();
	const [options, setOptions] = useState<Option[]>([]);
	const [saveLoading, setSaveLoading] = useState(false);
	const [searchParams] = useSearchParams();
	const [modal, contextHolder] = Modal.useModal();
	const [refresh, setRefresh] = useState<number>(0);

	// get users available tools
	useEffect(() => {
		(async () => {
			const { data } = await axios.get('/ruth-connect/integrations', { withCredentials: true });
			const options: Option[] = [];

			if (data.results.length === 0) {
				const appOptions = getUserOptions('generic');
				return setOptions(appOptions);
			}

			if (data.results.length > 0) {
				for (const app of data.results) {
					const appOptions = getUserOptions(app.app_name);
					if (appOptions) {
						options.push(...appOptions);
					}
				}

				setOptions(options);
			}
		})();
	}, [refresh]);

	// set refresh on window focus
	useEffect(() => {
		const handleFocus = () => setRefresh((prev) => prev + 1);
		window.addEventListener('focus', handleFocus);

		return () => window.removeEventListener('focus', handleFocus);
	}, []);

	// save tools
	const handleSave = async () => {
		setSaveLoading(true);
		// get assistant id from url
		const assistantId = searchParams.get('assistant');

		// if tools contain transfer_call and transfer_to is not filled, show error message
		if (tools.some((tool) => tool.functionId === 'transfer_call' && !tool.function.transfer_to)) {
			setSaveLoading(false);
			return modal.error({
				title: 'Error',
				content: 'Please fill the phone number to transfer the call to.',
				centered: true,
			});
		}

		// save tools
		await axios.patch(`/ai-assistants/${assistantId}`, { tools }, { withCredentials: true });
		toast.success('Tools saved successfully');

		// Reset save loading
		setSaveLoading(false);
	};

	// add tools dropdown
	const AddToolDropdown = () => {
		return (
			<Dropdown
				menu={{
					items: options.map((option) => ({
						key: option.key,
						icon: option.icon,
						label: option.label,
						onClick: () => {
							const tool = allTools.find((tool) => tool.payload.functionId === option.key);
							if (tool) {
								const payload = tool.payload;
								addTool(payload);
							}
						},
					})),
				}}
				trigger={['click']}
			>
				<Button
					size="large"
					icon={
						<img
							src={is_dark_mode ? '/images/ai-assistant/plus-icon-dark.svg' : '/images/ai-assistant/plus-icon.svg'}
							alt="plus-icon"
						/>
					}
					className="font-semibold"
				>
					Add Tool
				</Button>
			</Dropdown>
		);
	};

	return (
		<div className="min-h-80">
			{contextHolder}
			<h2 className="text-xl font-semibold text-black-7 dark:text-white">Tools (Optional)</h2>

			<div className="pt-4" />

			<div className="flex flex-wrap items-center justify-between gap-4">
				<p className="max-w-lg text-sm font-medium text-black-3">
					Enable your agent with capabilities such as calendar bookings, call termination, or your own custom functions. It can be triggered
					across all states.
				</p>

				<Space size="middle">
					{props.for === 'assistant' && (
						<Button
							size="large"
							type="primary"
							icon={
								<img
									src="/images/ai-assistant/save-icon.svg"
									alt="save-icon"
								/>
							}
							onClick={handleSave}
							loading={saveLoading}
							className="font-semibold"
						>
							Save
						</Button>
					)}

					<AddToolDropdown />
				</Space>
			</div>

			<div className="py-2" />

			{tools.length === 0 && (
				<div className="flex min-h-80 flex-col items-center justify-center gap-1">
					<p className="text-base font-medium text-black-3">You haven't added any tools yet. Click the button below to add a tool.</p>

					<div className="pt-2" />

					<div className="flex items-center gap-3">
						<AddToolDropdown />

						<Link
							to={'/ruth-connect'}
							target="_blank"
						>
							<Button
								size="large"
								type="dashed"
								className="font-semibold"
								icon={
									<img
										src="/images/layout/ruth-connect-active-icon.svg"
										alt="ruth-connect-icon"
									/>
								}
							>
								Ruth Connect
							</Button>
						</Link>
					</div>
				</div>
			)}

			{tools.length > 0 &&
				tools.map((tool) => {
					// find the tool from allTools
					const toolIndex = allTools.findIndex((t) => t.payload.functionId === tool.functionId);

					// if toolIndex is -1, the tool was not found in allTools
					if (toolIndex === -1) {
						return null;
					}

					// get the formUI
					const FormUI = allTools[toolIndex].formUI;

					// render the formUI
					return (
						<div
							key={tool.id}
							className="py-2"
						>
							<FormUI tool={tool} />
						</div>
					);
				})}
		</div>
	);
};

export default Tools;
