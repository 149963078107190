import { Button, Card, Input, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useReactFlow } from 'reactflow';
import { Tool } from '../../store/tools';
import { LlmStates } from '../../types/ai-assistant.types';

type Props = {
	llm_states: LlmStates;
	handleDeleteTool: (functionId: string) => void;
};

const CallTransfer: FC<Props> = (props) => {
	const { setNodes } = useReactFlow();

	// first check if the assistant already has the tool by functionId
	const tools = props.llm_states.tools || [];
	const tool = tools.find((tool) => tool.functionId === 'transfer_call');

	// Local state for description and transferTo
	const [description, setDescription] = useState('');
	const [transferTo, setTransferTo] = useState<string>('');

	// Set initial values when the component mounts or when the tool prop changes
	useEffect(() => {
		if (tool) {
			setDescription(tool.function.description.split('/')[0].trim());
			setTransferTo((tool.function.transfer_to as string) || '+1234567890');
		}
	}, [tool]);

	// handle name change in the input
	const handleToolNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNodes((prev) => {
			const newNodes = prev.map((node) => {
				if (node.id === props.llm_states.state_id) {
					return {
						...node,
						data: {
							...node.data,
							item: {
								...node.data.item,
								tools: node.data.item.tools.map((tool: Tool) => {
									if (tool.functionId === 'transfer_call') {
										return {
											...tool,
											function: {
												...tool.function,
												name: e.target.value,
											},
										};
									}
									return tool;
								}),
							},
						},
					};
				}
				return node;
			});
			return newNodes;
		});
	};

	// handle description change in the input
	const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const sanitizedValue = e.target.value.replace(/\//g, '');
		setDescription(sanitizedValue);
		setNodes((prev) => {
			const newNodes = prev.map((node) => {
				if (node.id === props.llm_states.state_id) {
					return {
						...node,
						data: {
							...node.data,
							item: {
								...node.data.item,
								tools: node.data.item.tools.map((tool: Tool) => {
									if (tool.functionId === 'transfer_call') {
										return {
											...tool,
											function: {
												...tool.function,
												description: `${sanitizedValue} / ${transferTo}`,
											},
										};
									}
									return tool;
								}),
							},
						},
					};
				}
				return node;
			});
			return newNodes;
		});
	};

	// handle transfer_to change in the input
	const handleTransferToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newTransferTo = e.target.value;
		setTransferTo(newTransferTo);
		setNodes((prev) => {
			const newNodes = prev.map((node) => {
				if (node.id === props.llm_states.state_id) {
					return {
						...node,
						data: {
							...node.data,
							item: {
								...node.data.item,
								tools: node.data.item.tools.map((tool: Tool) => {
									if (tool.functionId === 'transfer_call') {
										return {
											...tool,
											function: {
												...tool.function,
												transfer_to: newTransferTo,
												description: `${description} / ${newTransferTo}`,
											},
										};
									}
									return tool;
								}),
							},
						},
					};
				}
				return node;
			});
			return newNodes;
		});
	};

	return (
		<Card styles={{ body: { padding: 12 } }}>
			<div className="flex items-center justify-between gap-2">
				<h2 className="pb-2 text-lg font-bold text-black-7 dark:text-white">Call Transfer</h2>

				<Space>
					<Button
						icon={
							<img
								src="/images/ai-assistant/delete-icon.svg"
								alt="delete-icon"
							/>
						}
						onClick={() => {
							props.handleDeleteTool('transfer_call');
						}}
					/>
				</Space>
			</div>

			<div>
				<div>
					<div className="pb-2 font-semibold text-gray-600 dark:text-white">Name</div>

					<Input
						size="large"
						defaultValue={tool ? (tool.function.name as string) : 'transfer_call'}
						onChange={handleToolNameChange}
					/>
				</div>

				<div className="pt-5" />

				<div>
					<div className="pb-2 font-semibold text-gray-600 dark:text-white">Description</div>

					<Input
						value={description}
						size="large"
						onChange={handleDescriptionChange}
						maxLength={100}
						onKeyDown={(e) => {
							if (e.key === '/') {
								e.preventDefault();
							}
						}}
					/>
				</div>

				<div className="pt-5" />

				<div>
					<div className="pb-2 font-semibold text-gray-600 dark:text-white">Transfer To</div>

					<Input
						value={transferTo}
						size="large"
						onChange={handleTransferToChange}
					/>

					<small>Phone number must include + and country code, e.g. +1234567890</small>
				</div>
			</div>
		</Card>
	);
};

export default CallTransfer;
