import { useMutation, useQuery } from '@tanstack/react-query';
import { Alert, Card, DatePicker, Input, Select, Table, Upload, UploadFile } from 'antd';
import { AxiosError } from 'axios';
import Papa from 'papaparse';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import readXlsxFile from 'read-excel-file';
import * as XLSX from 'xlsx';
import LoadingIcon from '../components/loading-icon/loading-icon';
import axios from '../config/axios';
import { cn } from '../config/cn';
import dayjs from '../config/dayjs';
import AppLayout from '../layouts/app.layout';
import { AIAssistant } from '../types/ai-assistant.types';

type PhoneNumber = {
	to_number: string;
	[key: string]: string;
};

const CreateCampaign = () => {
	const navigate = useNavigate();
	const [fileName, setFileName] = useState('');
	const [dataSource, setDataSource] = useState([]);
	const [columns, setColumns] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [name, setName] = useState<string>('');
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [aiAssistantId, setAiAssistantId] = useState<number | null>(null);
	const [generateFileLoading, setGenerateFileLoading] = useState<boolean>(false);
	const [excelFile, setExcelFile] = useState<Blob | null>(null);
	const [csvFile, setCsvFile] = useState<Blob | null>(null);
	const [generateFileSuccess, setGenerateFileSuccess] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const handleGenerateFile = async (assistant: number) => {
		try {
			setGenerateFileLoading(true);
			// get variables for assistant id
			const { data } = await axios.get(`/ai-assistants/${assistant}`, { withCredentials: true });
			const variables: string[] = data.results.variables;

			// Generate 10 random phone numbers
			const phoneNumbers: PhoneNumber[] = Array.from({ length: 10 }, () => ({
				to_number: '',
				...variables.reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
			}));

			// Create a header row
			const headers = ['to_number', ...variables];

			// Combine headers and data
			const worksheetData = [headers, ...phoneNumbers.map((obj) => headers.map((header) => obj[header] || ''))];

			// Create a new workbook and a worksheet
			const workbook = XLSX.utils.book_new();
			const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

			// Append the worksheet to the workbook
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

			// Generate Excel file as Blob
			const excelBlob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })], { type: 'application/octet-stream' });
			setExcelFile(excelBlob);

			// Generate CSV file as Blob
			const csvData = XLSX.utils.sheet_to_csv(worksheet);
			const csvBlob = new Blob([csvData], { type: 'text/csv' });
			setCsvFile(csvBlob);

			// Set generated file success
			setGenerateFileSuccess(true);
		} catch (error) {
			console.error(error);
			setGenerateFileSuccess(false);
		} finally {
			setGenerateFileLoading(false);
		}
	};

	// Function to trigger download
	const triggerDownload = (file: Blob, fileName: string) => {
		const url = window.URL.createObjectURL(file);
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	};

	// Button click handlers
	const handleDownloadExcel = () => {
		if (excelFile) {
			triggerDownload(excelFile, 'puretalk_campaign_data_format.xlsx');
		}
	};

	const handleDownloadCSV = () => {
		if (csvFile) {
			triggerDownload(csvFile, 'puretalk_campaign_data_format.csv');
		}
	};

	// handle csv
	const handleCSV = (file: UploadFile) => {
		Papa.parse(file.originFileObj as File, {
			header: true,
			complete: (result: { data: unknown[] }) => {
				result.data.pop();

				const columns = Object.keys(result.data[0] as object).map((column) => ({
					title: column,
					dataIndex: column,
					key: column,
				}));

				setColumns(columns as never[]);
				setDataSource(result.data as never[]);
			},
		});
	};

	// handle excel
	const handleExcel = async (file: UploadFile) => {
		const res = await readXlsxFile(file.originFileObj as File);
		const columns = res[0].map((column) => ({
			title: column,
			dataIndex: column as string,
			key: column as string,
		}));

		// Process data starting from the second row
		const data = res.slice(1).map((row) => {
			const rowData: { [key: string]: unknown } = {};
			row.forEach((cell, index) => {
				rowData[columns[index].dataIndex] = cell;
			});
			return rowData;
		});

		setColumns(columns as never[]);
		setDataSource(data as never[]);
	};

	// get all ai assistants
	const { data: assistants, isLoading: aiAssistantsLoading } = useQuery<AIAssistant[]>({
		queryKey: ['ai-assistants'],
		queryFn: async () => {
			const { data } = await axios.get('/ai-assistants', { withCredentials: true, params: { limit: 50 } });

			return data.results;
		},
	});

	// handle start call
	const handleStartCall = async () => {
		// check if all fields are filled
		if (!name) return setErrorMessage('Please enter a campaign name.');
		if (!startDate) return setErrorMessage('Please select a start date.');
		if (!endDate) return setErrorMessage('Please select an end date.');
		if (!aiAssistantId) return setErrorMessage('Please select an AI Assistant.');
		if (!dataSource.length) return setErrorMessage('Please upload a CSV or Excel file with call data.');
		if (dataSource.some((item) => Object.values(item).some((value) => !value)))
			return setErrorMessage('Some fields in the table are empty. Please fill in all fields before starting the call campaign.');

		// prepare payload
		const payload = {
			name,
			start_date: dayjs(startDate).format('YYYY-MM-DD'),
			end_date: dayjs(endDate).format('YYYY-MM-DD'),
			type: 'call',
			ai_assistant_id: aiAssistantId,
			leads: dataSource.map((lead: { to_number: string; [key: string]: string }) => ({
				to_number: `+${lead.to_number}`,
				variables: Object.entries(lead)
					.map(([key, value]) => ({
						var_name: key,
						var_value: value,
					}))
					.filter((variable) => variable.var_name !== 'to_number'), // Exclude to_number from variables
			})),
		};

		// start call mutation
		startCall(payload);
	};

	// start call mutation
	const { mutate: startCall, isPending } = useMutation({
		mutationKey: ['start-call'],
		mutationFn: async (payload: unknown) => {
			const { data } = await axios.post('/campaigns', payload, { withCredentials: true });

			return data;
		},
		onSuccess: () => {
			toast.success('Call campaign started successfully');
			navigate('/campaigns');
		},
		onError: (error: Error) => {
			if (error instanceof AxiosError && error.response) {
				toast.error(error.response.data.msg);
			}
		},
	});

	// reset error message after 3 seconds
	useEffect(() => {
		const timeout = setTimeout(() => {
			setErrorMessage(null);
		}, 3000);

		return () => clearTimeout(timeout);
	}, [errorMessage]);

	return (
		<AppLayout
			title="Create Campaign"
			subtitle="Create a new call or SMS campaign."
		>
			<section>
				{searchParams.get('type') === null && (
					<section>
						<h2 className="text-2xl font-bold text-black-7 dark:text-white">Select Campaign Type</h2>

						<div className="pt-1" />

						<h4 className="text-base font-medium text-black-3">
							Select the type of campaign you would like to create. You can choose between a Call Campaign or an SMS campaign.
						</h4>

						<div className="flex gap-4 pt-4">
							{[
								{
									title: 'Call Campaign',
									description: 'Create a call campaign campaign to call a list of phone numbers with a custom prompt.',
									value: 'call',
								},
							].map((campaign, index) => (
								<Card
									key={index}
									onClick={() => {
										setSearchParams({
											type: campaign.value,
										});
									}}
									styles={{
										body: {
											padding: 16,
											width: 340,
										},
									}}
									role="button"
									className="transition-all hover:border-primary"
								>
									<h2 className="text-xl font-bold text-black-7 dark:text-white">{campaign.title}</h2>

									<div className="pt-1" />

									<p className="font-medium leading-5 text-black-3">{campaign.description}</p>
								</Card>
							))}
						</div>
					</section>
				)}

				{searchParams.get('type') === 'call' && (
					<section>
						<h2 className="text-xl font-bold text-black-7 dark:text-white">New Call Campaign</h2>

						<div className="space-y-6 pt-4">
							<div className="space-y-2">
								<label
									htmlFor="name"
									className="pl-0.5 text-base font-medium text-black-7 dark:text-white"
								>
									Campaign Name
								</label>
								<Input
									placeholder="Give your batch a name"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>

							<div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
								<div className="flex flex-col gap-2">
									<label
										htmlFor="start_date"
										className="pl-0.5 text-base font-medium text-black-7 dark:text-white"
									>
										Start Date
									</label>
									<DatePicker
										value={startDate}
										onChange={(date) => {
											setStartDate(date);
										}}
										inputReadOnly
										placeholder="Select Start Date"
									/>
								</div>

								<div className="flex flex-col gap-2">
									<label
										htmlFor="end_date"
										className="pl-0.5 text-base font-medium text-black-7 dark:text-white"
									>
										End Date
									</label>
									<DatePicker
										value={endDate}
										onChange={(date) => {
											setEndDate(date);
										}}
										inputReadOnly
										placeholder="Select End Date"
									/>
								</div>

								<div className="flex flex-col gap-2">
									<label
										htmlFor="ai_assistant_id"
										className="pl-0.5 text-base font-medium text-black-7 dark:text-white"
									>
										AI Assistant
									</label>
									<Select
										options={
											assistants &&
											assistants.map((assistant) => ({
												label: assistant.name,
												value: assistant.assistant_id,
											}))
										}
										placeholder="Select AI Assistant"
										loading={aiAssistantsLoading}
										virtual={false}
										value={aiAssistantId}
										onChange={(value) => {
											setAiAssistantId(value);
											handleGenerateFile(value);
										}}
									/>
								</div>
							</div>

							<div className="space-y-2">
								<div>
									<label
										htmlFor="call_data"
										className="pl-0.5 text-base font-medium text-black-7 dark:text-white"
									>
										Call Data
									</label>
									<p className="text-sm text-black-3">
										Upload a CSV or Excel file. The first row should be the headers of the table, and your headers should not
										include any special characters other than hyphens (-) or underscores (_). The column containing phone numbers
										must have a header labeled as "to_number". "to_number" will contain only number value. Download a desired
										format of the file below. Invalid data format will result in parse error.
									</p>

									{!aiAssistantId && (
										<div className="text-red-500">
											Please select an AI Assistant to upload a file or download a sample CSV or Excel file.
										</div>
									)}

									{aiAssistantId && generateFileLoading && <div className="text-green-600">Generating sample file...</div>}

									{aiAssistantId && !generateFileLoading && !generateFileSuccess && (
										<div className="text-red-600">Failed to generate sample file. Please try again.</div>
									)}

									{aiAssistantId && !generateFileLoading && generateFileSuccess ? (
										<div className="flex flex-wrap items-center gap-x-2">
											<div
												className="text-primary"
												onClick={handleDownloadCSV}
												role="button"
											>
												Download a sample CSV file
											</div>

											<span className='dark:text-white'> or </span>

											<div
												className="text-primary"
												onClick={handleDownloadExcel}
												role="button"
											>
												Download a sample Excel file
											</div>
										</div>
									) : null}
								</div>
								<div>
									<Upload
										onChange={(file) => {
											if (file.file.originFileObj) {
												setFileName(file.file.originFileObj.name);
											}
											if (file.file.type === 'text/csv') {
												handleCSV(file.file);
											}
											if (file.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
												handleExcel(file.file);
											}
										}}
										showUploadList={false}
										customRequest={() => {
											return null;
										}}
										accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
									>
										<button
											type="button"
											className="flex items-center gap-2 rounded-md bg-green-700 px-4 py-2 font-semibold text-white disabled:cursor-not-allowed disabled:opacity-50"
											disabled={!aiAssistantId}
										>
											{fileName ? fileName : 'Upload CSV or Excel'}
										</button>
									</Upload>
								</div>

								{dataSource.length > 0 && (
									<Table
										bordered
										size="middle"
										pagination={{
											showSizeChanger: false,
											showTotal: (total) => `Total ${total} items`,
										}}
										className="pt-1"
										dataSource={dataSource}
										columns={columns}
										rowKey={() => {
											return crypto.randomUUID();
										}}
									/>
								)}
							</div>

							{errorMessage && (
								<Alert
									type="error"
									message={errorMessage}
									banner
									closable={false}
									rootClassName={cn({
										'!mt-0': dataSource.length > 0,
									})}
								/>
							)}

							<button
								type="button"
								className={cn(
									'flex h-11 items-center gap-2 rounded-md bg-primary px-4 py-2 font-semibold text-white disabled:cursor-not-allowed disabled:opacity-50',
									{
										'!-mt-3 md:!-mt-6': dataSource.length > 0 && !errorMessage,
									},
								)}
								disabled={isPending}
								onClick={handleStartCall}
							>
								{isPending ? (
									<LoadingIcon />
								) : (
									<img
										src="/images/phone-numbers/make-outbound-call.svg"
										alt="make-outbound-call"
									/>
								)}

								<span>Start Call Campaign</span>
							</button>
						</div>
					</section>
				)}
			</section>

			<div className="pb-6" />
		</AppLayout>
	);
};

export default CreateCampaign;
