import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import { Avatar, Button, Select, Slider, Switch, Table } from 'antd';
import { FC, Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import axios from '../../config/axios';
import { useThemeStore } from '../../store/theme';
import { AIAssistant, Voice } from '../../types/ai-assistant.types';

type Props = {
	assistant: AIAssistant;
	refetch: () => void;
	refetchAssistant: () => void;
};

const AssistantVoices: FC<Props> = ({ assistant, refetch, refetchAssistant }) => {
	const { is_dark_mode } = useThemeStore();
	const [gender, setGender] = useState<'male' | 'female' | null>(null);
	const [selectedID, setSelectedID] = useState<number | null>(null);
	const [stability, setStability] = useState<number>(() => assistant.voice_stability * 100);
	const [claritySimilarity, setClaritySimilarity] = useState<number>(() => assistant.voice_clarity_similarity * 100);
	const [streamingLatency, setStreamingLatency] = useState<number>(() => assistant.voice_stream_latency_optimization);
	const [speakerBoost, setSpeakerBoost] = useState<boolean>(() => assistant.voice_boost);

	// get all voices
	const { data: voices, isLoading } = useQuery<Voice[]>({
		queryKey: ['voices', gender],
		queryFn: async () => {
			const { data } = await axios.get('/voices', { withCredentials: true, params: { limit: 10 } });

			// filter based on gender
			if (gender) data.results = data.results.filter((voice: Voice) => voice.gender === gender);

			return data.results;
		},
		placeholderData: keepPreviousData,
	});

	// update assistant voice
	const { mutate: updateAssistantVoice, isPending } = useMutation({
		mutationFn: async (voiceID: number) => {
			setSelectedID(voiceID);
			const { data } = await axios.patch(
				`/ai-assistants/${assistant.assistant_id}`,
				{
					voice: voiceID,
				},
				{ withCredentials: true },
			);

			return data;
		},
		onSuccess: () => {
			refetch();
			refetchAssistant();
		},
	});

	// config for options
	const configs = [
		{
			title: 'Stability',
			description: 'How stable do you want the voice to be? Higher values will make the voice more stable.',
			value: stability,
			min: 0,
			max: 100,
			leftLabel: 'Variable',
			rightLabel: 'Stable',
			onChange: (value: number) => {
				setStability(value);
			},
			onIncrement: () => {
				if (stability < 100) {
					setStability((prev) => Math.min(prev + 10, 100));
				}
			},
			onDecrement: () => {
				if (stability > 0) {
					setStability((prev) => Math.max(prev - 10, 0));
				}
			},
		},
		{
			title: 'Clarity + Similarity',
			description: 'How clear and similar to the original voice do you want the voice to be?',
			value: claritySimilarity,
			min: 0,
			max: 100,
			leftLabel: 'Low',
			rightLabel: 'High',
			onChange: (value: number) => {
				setClaritySimilarity(value);
			},
			onIncrement: () => {
				if (claritySimilarity < 100) {
					setClaritySimilarity((prev) => Math.min(prev + 10, 100));
				}
			},
			onDecrement: () => {
				if (claritySimilarity > 0) {
					setClaritySimilarity((prev) => Math.max(prev - 10, 0));
				}
			},
		},
		{
			title: 'Optimize Streaming Latency',
			description: 'Optimize the streaming latency of the voice. Lower values will make the voice stream faster.',
			value: streamingLatency,
			min: 0,
			max: 4,
			leftLabel: 'More Latency',
			rightLabel: 'Less Latency',
			onChange: (value: number) => {
				setStreamingLatency(value);
			},
			onIncrement: () => {
				if (streamingLatency < 4) {
					setStreamingLatency((prev) => Math.min(prev + 1, 4));
				}
			},
			onDecrement: () => {
				if (streamingLatency > 0) {
					setStreamingLatency((prev) => Math.max(prev - 1, 0));
				}
			},
		},
	];

	// update voice settings mutation
	const { mutate: updateVoiceSettings, isPending: updateVoiceSettingsPending } = useMutation({
		mutationKey: ['update-voice-settings'],
		mutationFn: async (payload: Record<string, unknown>) => {
			await axios.patch(`/ai-assistants/${assistant.assistant_id}`, payload, { withCredentials: true });
		},
		onSuccess: () => {
			toast.success('Voice settings updated successfully');
			refetch();
			refetchAssistant();
		},
	});

	return (
		<Fragment>
			<div className="flex flex-col gap-x-20 gap-y-6 2xl:flex-row 2xl:gap-y-0">
				<div className="w-full 2xl:w-80">
					<h2 className="text-xl font-semibold text-black-7 dark:text-white">Current Voice</h2>

					<div className="pt-4" />

					<div
						className="rounded-lg border p-2 dark:border-[#322f3d]"
						title="Current Assistant Voice"
					>
						<div className="flex items-center gap-2">
							<Avatar
								src={assistant.voice.avatar}
								size={40}
								shape="circle"
							/>
							<div className="text-lg font-medium dark:text-white">{assistant.voice.name}</div>
						</div>

						<div className="pt-3" />

						<div className="flex justify-between">
							<div>
								<div className="font-medium text-black-3">Provider</div>
								<div className="font-medium dark:text-white">{assistant.voice.provider}</div>
							</div>
							<div>
								<div className="font-medium text-black-3">Voice ID</div>
								<div className="font-medium dark:text-white">{assistant.voice.voice_id}</div>
							</div>
						</div>

						<div className="pt-4" />

						<div className="flex justify-between">
							<div role="button">
								<img
									src="/images/ai-assistant/play-icon.svg"
									alt="play-icon"
								/>
							</div>

							<img
								src={is_dark_mode ? '/images/ai-assistant/check-icon-dark.svg' : '/images/ai-assistant/check-icon.svg'}
								alt="check-icon"
							/>
						</div>
					</div>
				</div>
				<div className="flex-1">
					<div className="flex items-center justify-between gap-2">
						<h2 className="text-xl font-semibold text-black-7 dark:text-white">Select Other Voices</h2>
						<Select
							options={[
								{ value: 'male', label: 'Male' },
								{ value: 'female', label: 'Female' },
							]}
							placeholder="All Gender"
							value={gender}
							onChange={(value) => setGender(value)}
							allowClear
							className="w-48"
						/>
					</div>

					<div className="pt-2.5" />

					<Table
						dataSource={voices}
						size="middle"
						pagination={false}
						loading={isLoading}
						rowKey={(record) => record.id.toString()}
						bordered
						columns={[
							{
								title: <div className="font-medium text-black-3">Play</div>,
								dataIndex: 'play',
								key: 'play',
								render: () => (
									<img
										src="/images/ai-assistant/play-icon.svg"
										alt="play-icon"
										role="button"
									/>
								),
							},
							{
								title: <div className="font-medium text-black-3">Name</div>,
								dataIndex: 'name',
								key: 'name',
								render: (name: string, record: Voice) => (
									<div className="flex items-center gap-2">
										<Avatar
											src={record.avatar}
											size={35}
											shape="circle"
										/>

										<div className="font-medium">{name}</div>
									</div>
								),
							},
							{
								title: <div className="font-medium text-black-3">Voice ID</div>,
								dataIndex: 'voice_id',
								key: 'voice_id',
								render: (_, record) => (
									<div className="flex items-center justify-between">
										<div className="font-medium">{record.voice_id}</div>

										{isPending && selectedID === record.id ? (
											<div
												className="mt-2 inline-block size-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-[#22c55e]"
												role="status"
											/>
										) : record.id === assistant.voice.id ? (
											<img
												src={
													is_dark_mode
														? '/images/ai-assistant/radio-active-dark.svg'
														: '/images/ai-assistant/radio-active.svg'
												}
												alt="radio-active"
												role="button"
											/>
										) : (
											<img
												src={
													is_dark_mode
														? '/images/ai-assistant/radio-inactive-dark.svg'
														: '/images/ai-assistant/radio-inactive.svg'
												}
												alt="radio-inactive"
												role="button"
												onClick={() => {
													updateAssistantVoice(record.id);
												}}
											/>
										)}
									</div>
								),
							},
						]}
					/>
				</div>
			</div>

			<div className="py-4" />

			<section>
				<div className="flex items-center justify-between gap-2">
					<h2 className="text-xl font-semibold text-black-7 dark:text-white">Additional Configuration</h2>

					<Button
						type="primary"
						htmlType="submit"
						size="large"
						className="font-semibold"
						disabled={updateVoiceSettingsPending}
						loading={updateVoiceSettingsPending}
						icon={
							<img
								src="/images/ai-assistant/save-icon.svg"
								alt="save-icon"
							/>
						}
						onClick={() => {
							const payload = {
								voice_boost: speakerBoost,
								voice_stability: stability / 100,
								voice_clarity_similarity: claritySimilarity / 100,
								voice_stream_latency_optimization: streamingLatency,
							};

							updateVoiceSettings(payload);
						}}
					>
						Save
					</Button>
				</div>

				<div className="pt-4" />

				<div className="grid grid-cols-1 gap-x-8 gap-y-6 2xl:grid-cols-2">
					<div>
						<div className="text-base font-medium text-black-7 dark:text-white">Use Speaker Boost</div>

						<div className="pt-2" />

						<div className="flex items-center justify-between gap-3.5">
							<div className="text-sm font-medium text-black-3">
								Boost the similarity of the synthesized speech and the voice at the cost of some generation speed.
							</div>
							<Switch
								checked={speakerBoost}
								onChange={(value) => setSpeakerBoost(value)}
							/>
						</div>
					</div>

					{configs.map((item, index) => (
						<div key={index}>
							<div className="text-base font-medium text-black-7 dark:text-white">{item.title}</div>
							<p className="text-sm font-medium text-black-3">{item.description}</p>

							<div className="flex items-start gap-3">
								<div className="flex-1">
									<Slider
										value={item.value}
										min={item.min}
										max={item.max}
										onChange={item.onChange}
									/>

									<div className="-mt-1 flex justify-between gap-2 px-1.5 text-sm font-medium text-black-3">
										<div>{item.leftLabel}</div>
										<div>{item.rightLabel}</div>
									</div>
								</div>

								<div className="flex w-fit items-center gap-2 pt-1.5">
									<img
										src={is_dark_mode ? '/images/ai-assistant/minus-dark.svg' : '/images/ai-assistant/minus.svg'}
										alt="minus"
										role="button"
										onClick={item.onDecrement}
									/>

									<div className="rounded-md border px-4 py-0.5 text-sm font-medium dark:border-[#161422] dark:bg-[#1b1827] dark:text-white">
										{item.value}
										{item.title !== 'Optimize Streaming Latency' && '%'}
									</div>
									<img
										src={is_dark_mode ? '/images/ai-assistant/plus-dark.svg' : '/images/ai-assistant/plus.svg'}
										alt="plus"
										role="button"
										onClick={item.onIncrement}
									/>
								</div>
							</div>
						</div>
					))}
				</div>
			</section>

			<div className="pb-8" />
		</Fragment>
	);
};

export default AssistantVoices;
