import { Button, Card, Input, Space } from 'antd';
import { FC, useState } from 'react';
import { Tool, useToolsStore } from '../../../store/tools';

type Props = {
	tool: Tool;
};

const CallTransfer: FC<Props> = ({ tool }) => {
	const { deleteTool, updateTool } = useToolsStore();
	const [description, setDescription] = useState(tool.function.description.split('/')[0].trim());
	const [transferTo, setTransferTo] = useState<string>(tool.function.transfer_to as string);

	return (
		<Card styles={{ body: { padding: 12 } }}>
			<div className="flex items-center justify-between gap-2">
				<h2 className="pb-2 text-lg font-bold text-black-7 dark:text-white">Call Transfer</h2>

				<Space>
					<Button
						icon={
							<img
								src="/images/ai-assistant/delete-icon.svg"
								alt="delete-icon"
							/>
						}
						onClick={() => {
							deleteTool(tool.id);
						}}
					/>
				</Space>
			</div>

			<div>
				<div>
					<div className="pb-2 text-base font-medium text-black-7 dark:text-white">Name</div>

					<Input
						size="large"
						defaultValue={tool.function.name}
						onChange={(e) => {
							updateTool(tool.id, {
								...tool,
								function: {
									...tool.function,
									name: e.target.value,
								},
							});
						}}
						className="bg-input-bg dark:bg-[#1b1827]"
					/>
				</div>

				<div className="pt-5" />

				<div>
					<div className="pb-2 text-base font-medium text-black-7 dark:text-white">Description</div>

					<Input
						defaultValue={description}
						size="large"
						onKeyDown={(e) => {
							if (e.key === '/') {
								e.preventDefault();
							}
						}}
						onChange={(e) => {
							setDescription(e.target.value);
							updateTool(tool.id, {
								...tool,
								function: {
									...tool.function,
									description: `${e.target.value} / ${transferTo}`,
								},
							});
						}}
						className="bg-input-bg dark:bg-[#1b1827]"
						maxLength={100}
					/>
				</div>

				<div className="pt-5" />

				<div>
					<div className="pb-2 text-base font-medium text-black-7 dark:text-white">Transfer To</div>

					<Input
						defaultValue={transferTo || ''}
						size="large"
						onChange={(e) => {
							setTransferTo(e.target.value);
							updateTool(tool.id, {
								...tool,
								function: {
									...tool.function,
									transfer_to: e.target.value,
									description: `${description} / ${e.target.value}`,
								},
							});
						}}
						className="bg-input-bg dark:bg-[#1b1827]"
					/>

					<small>Phone number must include + and country code, e.g. +1234567890</small>
				</div>
			</div>
		</Card>
	);
};

export default CallTransfer;
