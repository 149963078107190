import { useMutation } from '@tanstack/react-query';
import { Button, Drawer, Modal } from 'antd';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import ReactFlow, { Background, Connection, Edge, Node, ReactFlowProvider, addEdge, useEdgesState, useNodesState, useReactFlow } from 'reactflow';
import 'reactflow/dist/style.css';
import axios from '../../config/axios';
import { useThemeStore } from '../../store/theme';
import { AIAssistant, LlmStates } from '../../types/ai-assistant.types';
import CustomEdge from './custom-edge';
import CustomNode from './custom-node';
import { formatDataForAPI, generateNodesAndEdges } from './helpers';

type Props = {
	open: boolean;
	close: () => void;
	assistant: AIAssistant;
	refetchAssistant: () => void;
};

const MultiPromptTree: FC<Props> = (props) => {
	const nodeTypes = useMemo(() => ({ CustomNode: CustomNode }), []);
	const edgeTypes = useMemo(() => ({ CustomEdge: CustomEdge }), []);
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
	const [deleteNodesLoading, setDeleteNodesLoading] = useState(false);

	const { nodes: initialNodes, edges: initialEdges } = generateNodesAndEdges(props.assistant.llm_states);

	const initialNodesRef = useRef<Node[]>(initialNodes);
	const initialEdgesRef = useRef<Edge[]>(initialEdges);

	const [nodes, setNodes, onNodesChange] = useNodesState(initialNodesRef.current);
	const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdgesRef.current);
	const [nodeId, setNodeId] = useState<number>(nodes.length + 1);

	const { is_dark_mode } = useThemeStore();
	const [modal, contextHolder] = Modal.useModal();

	const onConnect = useCallback(
		(params: Edge | Connection) => {
			const newEdge = {
				...params,
				id: `${params.source}-${params.target}`,
				label: 'Edge',
				animated: true,
				type: 'CustomEdge',
				data: {
					description: 'Description for the edge',
					prompt: 'Task for the edge',
					parameters: null,
					state_id: (nodeId - 1).toString(),
				},
			};
			setEdges((eds) => addEdge(newEdge, eds));
		},
		[nodeId, setEdges],
	);

	const addNode = () => {
		const newNode: Node = {
			id: nodeId.toString(),
			position: {
				x: Math.random() * 200,
				y: Math.random() * 200,
			},
			data: {
				item: {
					state_id: nodeId.toString(),
					name: `new_state_${+nodeId}`,
					description: 'Description for the state',
					prompt: 'Task for the state',
					parameters: null,
					children: [],
					tools: [],
				},
				initial: nodes.length === 0 ? true : false,
			},
			type: 'CustomNode',
		};
		setNodes((nds) => nds.concat(newNode));
		setNodeId((id) => id + 1);
	};

	const { zoomIn, zoomOut } = useReactFlow();

	// get the saved position of the video node from local storage
	const getSavedPosition = () => {
		const savedPosition = localStorage.getItem('video-node-position');
		return savedPosition ? JSON.parse(savedPosition) : { x: -300, y: 200 };
	};

	// add some static nodes that will not affect the state of the component and will show a video tutorial
	useEffect(() => {
		const videoNode = {
			id: 'video-node',
			type: 'CustomNode',
			position: getSavedPosition(),
			data: {
				item: {
					static: true,
					url: 'https://www.youtube.com/watch?v=dbeCNiBuv3Q',
				},
			},
		};

		// get position of the video node
		const video_node = nodes.find((node) => node.id === 'video-node');

		// save the position of the video node in local storage
		if (video_node) {
			localStorage.setItem('video-node-position', JSON.stringify(video_node.position));
		}

		// check if the video node already exists in the nodes array
		if (nodes.some((node) => node.id === 'video-node')) return;

		setNodes((nodes) => [...nodes, videoNode]);
	}, [nodes, setNodes]);

	// save nodes mutation
	const { mutate: saveNodes, isPending } = useMutation({
		mutationFn: async (payload: LlmStates[]) => {
			await axios.post(
				`/ai-assistants/llm-states/${props.assistant.assistant_id}`,
				{
					llm_states: payload,
				},
				{ withCredentials: true },
			);
			return payload;
		},
		onSuccess: async () => {
			// refetch the assistant after a successful save
			props.refetchAssistant();

			// Update the initialNodes and initialEdges after a successful save
			// Use the current state of nodes and edges instead of the payload
			initialNodesRef.current = nodes;
			initialEdgesRef.current = edges;

			setHasUnsavedChanges(false); // No unsaved changes after successful save
			toast.success('Agent saved successfully');
		},
		onError: () => {
			modal.error({
				title: 'Error',
				content: "Couldn't save agent. Please try again later.",
				centered: true,
			});
		},
		onSettled: () => {
			setDeleteNodesLoading(false);
		},
	});

	useEffect(() => {
		const unsavedChanges = nodes
			.filter((node) => !node.data.item.static)
			.some((node, index) => {
				// Check if node data has changed
				const hasDataChanged = JSON.stringify(node.data.item) !== JSON.stringify(initialNodesRef.current[index]?.data.item);
				// Check if new node has been added
				const isNewNode = !initialNodesRef.current[index];

				return hasDataChanged || isNewNode;
			});

		// Check if any nodes have been deleted
		const deletedNodes = initialNodesRef.current.filter((initialNode) => !nodes.some((node) => node.id === initialNode.id)).length > 0;

		setHasUnsavedChanges(unsavedChanges || deletedNodes);
	}, [nodes]);

	// if user tries to close the browser, check if there are any unsaved changes, and prompt the user
	useEffect(() => {
		const handleBeforeUnload = (e: BeforeUnloadEvent) => {
			if (hasUnsavedChanges) {
				// Standard way to show the browser prompt
				const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
				e.preventDefault(); // Standard way to cancel the event
				e.returnValue = confirmationMessage; // Gecko and Trident
				return confirmationMessage; // Gecko and WebKit
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [hasUnsavedChanges]);

	// handle save node
	const handleSaveNode = async () => {
		// remove the static node from the nodes array
		const filteredNodes = nodes.filter((node) => !node.data.item.static);

		const { llm_states } = formatDataForAPI(filteredNodes, edges);

		// check if there is any node except the initial node that has no parent
		let hasError = false;
		filteredNodes.forEach((node, index) => {
			if (node.data.initial || index === 0) return;
			if (!edges.some((edge) => edge.target === node.id)) {
				hasError = true;
			}
		});

		if (hasError) {
			modal.error({
				title: 'Error',
				content: 'Please ensure all states have a parent',
				centered: true,
			});
			return;
		}

		// check recursively in llm_states if there is any object that has prompt and description as empty
		const checkPromptDescription = (data: LlmStates) => {
			if (data.prompt === '' || data.description === '') {
				hasError = true;
			}
			if (data.children) {
				data.children.forEach((child) => {
					checkPromptDescription(child);
				});
			}
		};

		llm_states.forEach((state) => {
			checkPromptDescription(state);
		});

		if (hasError) {
			modal.error({
				title: 'Error',
				content: 'Please ensure all states have a prompt and path condition',
				centered: true,
			});
			return;
		}

		// check if the tools contain transfer_call and transfer_to field is not filled, check if recursively
		const checkTransferToField = (data: LlmStates) => {
			if (data.tools) {
				data.tools.forEach((tool) => {
					if (tool.functionId === 'transfer_call' && !tool.function.transfer_to) {
						hasError = true;
					}
				});
			}
			if (data.children) {
				data.children.forEach((child) => {
					checkTransferToField(child);
				});
			}
		};

		llm_states.forEach((state) => {
			checkTransferToField(state);
		});

		if (hasError) {
			modal.error({
				title: 'Error',
				content: 'Fill the transfer to field in all call transfer tool',
				centered: true,
			});
			return;
		}

		// set unsaved changes to false
		setHasUnsavedChanges(false);

		// Check if any node has been deleted, store the deleted nodes state_id in an array
		const deletedNodes: string[] = [];
		initialNodesRef.current.forEach((node) => {
			if (!filteredNodes.some((n) => n.id === node.id) && node.data.item.state_id) {
				deletedNodes.push(node.data.item.state_id);
			}
		});

		if (deletedNodes.length > 0) {
			setDeleteNodesLoading(true);
			try {
				await axios.delete('/ai-assistants/llm-states/actions/delete', {
					data: { llm_states: deletedNodes },
					withCredentials: true,
				});
			} catch (error) {
				console.error('Error deleting nodes:', error);
			} finally {
				setDeleteNodesLoading(false);
			}
		}

		saveNodes(llm_states);
	};

	return (
		<Drawer
			open={props.open}
			onClose={() => {
				if (hasUnsavedChanges) {
					modal.confirm({
						title: 'Unsaved Changes',
						content: 'You have unsaved changes. Are you sure you want to leave?',
						centered: true,
						onOk: () => {
							setNodes(initialNodesRef.current);
							setEdges(initialEdgesRef.current);
							props.close();
						},
					});
				} else {
					props.close();
				}
			}}
			footer={null}
			width={'100%'}
			styles={{
				body: { padding: 0, position: 'relative' },
			}}
			push={false}
			destroyOnClose
			title={
				<div className="flex flex-wrap items-center justify-end gap-2 px-1 min-[405px]:justify-between">
					<div className="text-lg font-semibold">Multi Task Tree</div>
					<div className="flex items-center gap-3">
						<Button
							icon={
								<img
									src={is_dark_mode ? '/images/ai-assistant/zoom-in-dark.svg' : '/images/ai-assistant/zoom-in.svg'}
									alt="zoom-in"
								/>
							}
							size={'large'}
							onClick={() => {
								zoomIn();
							}}
						/>

						<Button
							size={'large'}
							icon={
								<img
									src={is_dark_mode ? '/images/ai-assistant/zoom-out-dark.svg' : '/images/ai-assistant/zoom-out.svg'}
									alt="zoom-out"
								/>
							}
							onClick={() => {
								zoomOut();
							}}
						/>
						<Button
							type="primary"
							icon={
								<img
									src="/images/ai-assistant/save-icon.svg"
									alt="save-icon"
								/>
							}
							onClick={handleSaveNode}
							size={'large'}
							loading={isPending || deleteNodesLoading}
							disabled={isPending || deleteNodesLoading}
							className="font-medium"
						>
							Save
						</Button>

						<Button
							icon={
								<img
									src={is_dark_mode ? '/images/ai-assistant/test-agent-icon-dark.svg' : '/images/ai-assistant/test-agent-icon.svg'}
									alt="test-agent-icon"
								/>
							}
							onClick={() => {
								console.log('Test Agent');
							}}
							size={'large'}
							className="hidden font-medium md:inline-flex"
						>
							Test Agent
						</Button>
					</div>
				</div>
			}
			closeIcon={
				<img
					src={
						is_dark_mode
							? '/images/ai-assistant/multi-task-tree-drawer-back-icon-dark.svg'
							: '/images/ai-assistant/multi-task-tree-drawer-back-icon-light.svg'
					}
					alt="close"
				/>
			}
		>
			{contextHolder}
			<ReactFlow
				nodes={nodes}
				edges={edges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				onConnect={onConnect}
				nodeTypes={nodeTypes}
				defaultViewport={{ x: 450, y: 100, zoom: 1.2 }}
				edgeTypes={edgeTypes}
				proOptions={{
					hideAttribution: true,
				}}
			>
				<div className="absolute bottom-16 left-1/2 z-50 flex -translate-x-1/2 transform gap-3 rounded-lg border bg-white p-4 py-3 dark:border-[#2d2b38] dark:bg-[#161422]">
					<Button
						type="primary"
						icon={
							<img
								src="/images/ai-assistant/new-state-icon.svg"
								alt="new-state-icon"
							/>
						}
						onClick={addNode}
						size={'large'}
						className="font-medium"
					>
						New State
					</Button>

					<Button
						icon={
							<img
								src={
									is_dark_mode
										? '/images/ai-assistant/test-agent-audio-icon-dark.svg'
										: '/images/ai-assistant/test-agent-audio-icon.svg'
								}
								alt="test-agent-audio-icon"
							/>
						}
						onClick={() => {
							console.log('Test Agent Audio');
						}}
						size={'large'}
						className="font-medium"
					>
						Test Agent Audio
					</Button>
				</div>

				<Background gap={12} />
			</ReactFlow>
		</Drawer>
	);
};

const WrappedMultiPromptTree: FC<Props> = (props) => (
	<ReactFlowProvider>
		<MultiPromptTree {...props} />
	</ReactFlowProvider>
);

export default WrappedMultiPromptTree;
