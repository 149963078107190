import { useMutation } from '@tanstack/react-query';
import { Button, Input } from 'antd';
import { AxiosError } from 'axios';
import { FC, Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import axios from '../../config/axios';
import { AIAssistant } from '../../types/ai-assistant.types';

type OutboundCallPayload = {
	from_number: string;
	to_number: string;
	variables: {
		var_name: string;
		var_value: string;
	}[];
};

type Props = {
	from_number: string;
	close: () => void;
	variables: AIAssistant['variables'];
};

const MakeOutboundCall: FC<Props> = (props) => {
	const [toNumber, setToNumber] = useState<string>('');
	const [alertMessage, setAlertMessage] = useState<string | null>(null);
	const [variables, setVariables] = useState<
		{
			var_name: string;
			var_value: string;
		}[]
	>([]);

	// handle outbound call
	const handleOutboundCall = () => {
		if (!toNumber) return setAlertMessage('Enter a valid phone number.');

		const payload = {
			from_number: props.from_number,
			to_number: toNumber,
			variables,
		};

		// call mutation
		mutate(payload);
	};

	// outbound call mutation
	const { mutate, isPending } = useMutation({
		mutationFn: async (payload: OutboundCallPayload) => {
			await axios.post('/calls/make-outbound', payload, { withCredentials: true });
			return true;
		},
		onSuccess: () => {
			toast.success('Outbound call successful!');
			setToNumber('');
			setVariables([]);
			props.close();
		},
		onError: (error: AxiosError<{ msg: string }>) => {
			if (error.response) {
				setAlertMessage(error.response.data.msg as string);
			}
		},
	});

	// close error message after 3 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			setAlertMessage(null);
		}, 3000);

		return () => clearTimeout(timer);
	}, [alertMessage]);

	return (
		<Fragment>
			<h2 className="text-xl font-semibold text-black-7 dark:text-white">Enter Phone Number</h2>

			<div className="pb-3 font-medium text-black-3">
				Remember to prefix your number with the country code without space, e.g., +11234567890
			</div>

			<Input
				placeholder="Enter phone number"
				size="large"
				value={toNumber}
				onChange={(e) => {
					if (e.target.value.startsWith('+')) {
						setToNumber(e.target.value);
					} else {
						setToNumber(`+${e.target.value}`);
					}
				}}
				type="text"
			/>

			{props.variables.length > 0 && (
				<Fragment>
					<div className="pt-4" />

					<h2 className="text-lg font-semibold text-black-7 dark:text-white">Enter Variables</h2>

					<div className="pb-3 font-medium text-black-3">Add variables to personalize the call. These variables are optional.</div>

					<div className="flex flex-col gap-3">
						{props.variables.map((variable) => (
							<div
								key={variable}
								className="flex items-center gap-2"
							>
								<div className="w-max text-base font-semibold">{`{{${variable}}}`}</div>
								<Input
									placeholder="Enter variable value"
									onChange={(e) =>
										setVariables((prevVariables) => {
											const variables = prevVariables.filter((v) => v.var_name !== variable || e.target.value);
											const index = variables.findIndex((v) => v.var_name === variable);

											if (index === -1 && e.target.value) {
												variables.push({ var_name: variable, var_value: e.target.value });
											} else if (index !== -1) {
												variables[index].var_value = e.target.value;
											}

											return variables;
										})
									}
								/>
							</div>
						))}
					</div>
				</Fragment>
			)}

			{alertMessage && <div className="pt-3 text-red-500">{alertMessage}</div>}

			<div className="pt-4" />

			<Button
				type="primary"
				block
				size="large"
				icon={
					<img
						src="/images/phone-numbers/make-outbound-call.svg"
						alt="make-outbound-call"
					/>
				}
				onClick={handleOutboundCall}
				loading={isPending}
			>
				Call Now
			</Button>
		</Fragment>
	);
};

export default MakeOutboundCall;
