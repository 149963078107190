import { useQuery } from '@tanstack/react-query';
import { Button, Input, Modal, Select } from 'antd';
import { FC, Fragment, useState } from 'react';
import { GoFileDiff } from 'react-icons/go';
import { GrRevert } from 'react-icons/gr';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IoChevronForwardSharp } from 'react-icons/io5';
import { LuScrollText } from 'react-icons/lu';
import { TbMessageCircleCode } from 'react-icons/tb';
import axios from '../../config/axios';
import { colors } from '../../theme/colors';
import FormLabel from './form-label';
import { Tab, tabs } from './tabs-data';

type KnowledgeFormProps = {
	setActiveTab: (tab: Tab) => void;
	prompt: string;
	setPrompt: (prompt: string) => void;
	customKnowledge: string;
	setCustomKnowledge: (knowledge: string) => void;
	contextTimeZone: string;
	setContextTimeZone: (timeZone: string) => void;
};

const KnowledgeForm: FC<KnowledgeFormProps> = (props) => {
	const [selectedContexts, setSelectedContexts] = useState<string[]>(['Current Date & Time']);
	const [selectedTimeZone, setSelectedTimeZone] = useState<string>('America/New_York');
	const [modal, contextHolder] = Modal.useModal();

	// handle next button click
	const handleNextButtonClick = () => {
		if (selectedContexts.includes('Current Date & Time')) {
			props.setContextTimeZone(selectedTimeZone);
		}

		if (!props.prompt) {
			return modal.error({
				title: 'Error',
				content: 'Please enter a prompt message.',
				centered: true,
			});
		}

		// set the next tab
		props.setActiveTab(tabs[3]);
	};

	// get all timezones
	const { data: timezones } = useQuery<string[]>({
		queryKey: ['timezones'],
		queryFn: async () => {
			const { data } = await axios.get('/commons/timezones', { withCredentials: true });

			return data.results;
		},
	});

	return (
		<Fragment>
			{contextHolder}
			<div>
				<FormLabel
					label="AGENT PROMPT"
					icon={<TbMessageCircleCode size={20} />}
				/>

				<div className="pt-1" />

				<p className="text-sm font-semibold text-gray-500">
					Give instructions to your AI about how it should behave and interact with others in conversation.
				</p>

				<div className="pt-3" />

				<Input.TextArea
					size="large"
					value={props.prompt}
					onChange={(e) => props.setPrompt(e.target.value)}
					placeholder="e.g. You are a customer support agent. You will try to respond to the user's questions with the best answers given your knowledge. You will never make up information."
					autoSize={{ minRows: 5, maxRows: 7 }}
				/>
			</div>

			<div className="pt-6" />

			<div>
				<FormLabel
					label="CUSTOM KNOWLEDGE"
					icon={<LuScrollText size={20} />}
				/>

				<div className="pt-1" />

				<p className="text-sm font-semibold text-gray-500">Add your custom knowledge to your agent.</p>

				<div className="pt-3" />

				<Input.TextArea
					size="large"
					value={props.customKnowledge}
					onChange={(e) => props.setCustomKnowledge(e.target.value)}
					placeholder="i.e. The more specialized knowledge and information your agent has, the closer to your expectations they will perform. If you're using an agent for Business, upload things like Business Hours, Answers to Frequently Asked Questions, Customer Service Policies, etc."
					autoSize={{ minRows: 5, maxRows: 7 }}
				/>
			</div>

			<div className="pt-6" />

			<div>
				<FormLabel
					label="DYNAMIC CONTEXT"
					icon={<GoFileDiff size={20} />}
				/>

				<div className="pt-1" />

				<p className="text-sm font-semibold text-gray-500">Enrich your assistant with context-aware knowledge.</p>

				<div className="pt-3" />

				<div className="grid grid-cols-1 gap-4">
					<div
						className="flex items-center justify-between rounded-xl border px-3 py-2 dark:border-[#322f3d]"
						role="button"
						onClick={() => {
							// if the context is already selected, remove it
							if (selectedContexts.includes('Current Date & Time')) {
								setSelectedContexts(selectedContexts.filter((context) => context !== 'Current Date & Time'));
							} else {
								setSelectedContexts([...selectedContexts, 'Current Date & Time']);
							}
						}}
					>
						<div className="max-w-sm flex-1">
							<div className="font-semibold">Current Date & Time</div>
							<div className="text-sm text-gray-500">Tell your assistant the current date and time</div>

							<div className="pt-2" />

							<Select
								options={timezones?.map((item) => ({ label: item, value: item }))}
								value={selectedTimeZone}
								onChange={(value) => setSelectedTimeZone(value)}
								className="md:w-full"
								size="large"
								placeholder="Select Timezone"
								onClick={(e) => {
									e.stopPropagation();
								}}
								virtual={false}
								showSearch
								optionFilterProp="label"
							/>
						</div>

						{selectedContexts.includes('Current Date & Time') ? (
							<div>
								<IoIosCheckmarkCircle
									color={colors.primary}
									size={20}
								/>
							</div>
						) : null}
					</div>
				</div>
			</div>

			<div className="pt-6" />

			<div className="grid grid-cols-2 gap-5">
				<Button
					size="large"
					type="dashed"
					block
					icon={<GrRevert />}
					onClick={() => {
						props.setActiveTab(tabs[1]);
					}}
				>
					Back
				</Button>

				<Button
					size="large"
					type="primary"
					block
					icon={<IoChevronForwardSharp />}
					iconPosition="end"
					onClick={handleNextButtonClick}
				>
					Next
				</Button>
			</div>
		</Fragment>
	);
};

export default KnowledgeForm;
