import { AxiosError } from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import axios from '../../config/axios';
import { cn } from '../../config/cn';
import { Tool } from '../../store/tools';
import { Voice } from '../../types/ai-assistant.types';
import { handleAPIError } from '../../utils/helpers';
import ActionsForm from './actions-form';
import BehaviorForm from './behavior-form';
import { AssistantType, Template } from './create-ai-assistant-data';
import DeployForm from './deploy-form';
import IdentityForm from './identity-form';
import KnowledgeForm from './knowledge-form';
import { Tab, tabs } from './tabs-data';

type CreateAIAssistantWidgetProps = {
	selectedTemplate: Template;
	selectedAssistantType: AssistantType;
	refetch: () => void;
	close: () => void;
};

const CreateAIAssistantWidget: FC<CreateAIAssistantWidgetProps> = (props) => {
	const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);
	const [name, setName] = useState<string | null>(() => props.selectedTemplate.name);
	const [voice, setVoice] = useState<Voice | null>(null);
	const [avatar, setAvatar] = useState<string | null>(() => props.selectedTemplate.avatar);
	const [beginMessage, setBeginMessage] = useState<string | null>(() => props.selectedTemplate.begin_message);
	const [whoSpeaksFirst, setWhoSpeaksFirst] = useState<string>(() => props.selectedTemplate.who_speaks_first);
	const [prompt, setPrompt] = useState<string | null>(() => props.selectedTemplate.prompt);
	const [customKnowledge, setCustomKnowledge] = useState<string>(() => '');
	const [contextTimeZone, setContextTimeZone] = useState<string>('America/New_York');
	const [selectedTools, setSelectedTools] = useState<string[]>([]);
	const [tools, setTools] = useState<Tool[]>([]);
	const [selectedPhone, setSelectedPhone] = useState<string | null>(null);
	const [createAssistantLoading, setCreateAssistantLoading] = useState<boolean>(false);

	// handle submit form
	const handleSubmit = async () => {
		const payload = {
			name,
			voice: voice && voice.id,
			prompt,
			model: props.selectedTemplate.model,
			who_speaks_first: whoSpeaksFirst,
			begin_message: whoSpeaksFirst === 'assistant' ? beginMessage : null,
			assistant_type: props.selectedAssistantType && props.selectedAssistantType.value,
			custom_knowledge_base: customKnowledge,
			avatar,
			timezone: contextTimeZone,
		};

		try {
			setCreateAssistantLoading(true);

			// create the assistant and get the assistant id
			const { data } = await axios.post('/ai-assistants', payload, { withCredentials: true });

			// create the assistant tools (if any)
			if (tools.length > 0) {
				// format transfer_call tools to add the transfer_to field in the description
				const formattedTools = tools.map((tool) => {
					if (tool.function.name === 'transfer_call') {
						const transferTo = tool.function.transfer_to as string;
						return {
							...tool,
							function: {
								...tool.function,
								description: `${tool.function.description.split('/')[0].trim()} / ${transferTo}`,
							},
						};
					}

					return tool;
				});

				// create the assistant tools
				try {
					await axios.patch(
						`/ai-assistants/${data.results.assistant_id}`,
						{
							tools: formattedTools,
						},
						{ withCredentials: true },
					);
				} catch (error) {
					handleAPIError(error as AxiosError);
				}
			}

			// link the phone number to assistant (if any)
			if (selectedPhone) {
				try {
					await axios.patch(
						`/phone-numbers/${selectedPhone}`,
						{
							ai_assistant: data.results.assistant_id,
						},
						{ withCredentials: true },
					);
				} catch (error) {
					handleAPIError(error as AxiosError);
				}
			}

			// add llm states to assistant (if any)
			if (props.selectedAssistantType.value === 'multi_prompt' && props.selectedTemplate.llm_states) {
				try {
					await axios.post(
						`/ai-assistants/llm-states/${data.results.assistant_id}`,
						{
							llm_states: props.selectedTemplate.llm_states,
						},
						{ withCredentials: true },
					);
				} catch (error) {
					handleAPIError(error as AxiosError);
				}
			}

			toast.success('Assistant created successfully.');
		} catch (error) {
			handleAPIError(error as AxiosError);
		} finally {
			setCreateAssistantLoading(false);
			props.refetch();
			props.close();
		}
	};

	return (
		<Fragment>
			<div className="scrollbar-hidden w-[calc(100dvw-4.5rem)] overflow-x-scroll md:w-full">
				<div className="inline-flex justify-between border-b-2 min-[665px]:flex dark:border-b-[#2d2b38]">
					{tabs.map((tab, index) => (
						<div
							key={index}
							className={cn('relative flex flex-1 items-center justify-between gap-4 rounded-md rounded-b-none px-4 py-3', {
								'bg-gray-100 transition-all duration-500 dark:bg-gray-800': activeTab.label === tab.label,
							})}
							role="button"
						>
							<div className="flex items-center gap-2">
								<div>{tab.icon}</div>
								<div className="text-base font-semibold text-gray-700 dark:text-gray-200">{tab.label}</div>
							</div>

							{tab.label === activeTab.label && (
								<div className="text-primary">
									<IoIosCheckmarkCircle size={18} />
								</div>
							)}

							{tab.label === activeTab.label && (
								<motion.div
									className="absolute -bottom-0.5 left-0 right-0 h-0.5 bg-primary"
									layoutId="underline"
								/>
							)}
						</div>
					))}
				</div>
			</div>

			<AnimatePresence mode="wait">
				<motion.div
					key={activeTab ? activeTab.label : 'empty'}
					initial={{ y: 10, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					exit={{ y: -10, opacity: 0 }}
					transition={{ duration: 0.2 }}
					className="pt-6"
				>
					{activeTab.label === 'Identity' && (
						<IdentityForm
							name={name as string}
							setName={setName}
							voice={voice}
							setVoice={setVoice}
							avatar={avatar as string}
							setAvatar={setAvatar}
							setActiveTab={setActiveTab}
						/>
					)}

					{activeTab.label === 'Behavior' && (
						<BehaviorForm
							beginMessage={beginMessage as string}
							setBeginMessage={setBeginMessage}
							whoSpeaksFirst={whoSpeaksFirst}
							setWhoSpeaksFirst={setWhoSpeaksFirst}
							setActiveTab={setActiveTab}
						/>
					)}

					{activeTab.label === 'Knowledge' && (
						<KnowledgeForm
							setActiveTab={setActiveTab}
							prompt={prompt as string}
							setPrompt={setPrompt}
							customKnowledge={customKnowledge as string}
							setCustomKnowledge={setCustomKnowledge}
							contextTimeZone={contextTimeZone}
							setContextTimeZone={setContextTimeZone}
						/>
					)}

					{activeTab.label === 'Actions' && (
						<ActionsForm
							setActiveTab={setActiveTab}
							tools={tools}
							setTools={setTools}
							selectedTools={selectedTools as string[]}
							setSelectedTools={setSelectedTools}
						/>
					)}

					{activeTab.label === 'Deploy' && (
						<DeployForm
							setActiveTab={setActiveTab}
							selectedPhone={selectedPhone}
							setSelectedPhone={setSelectedPhone}
							handleSubmit={handleSubmit}
							createAssistantLoading={createAssistantLoading}
						/>
					)}
				</motion.div>
			</AnimatePresence>
		</Fragment>
	);
};

export default CreateAIAssistantWidget;
