import { useQuery } from '@tanstack/react-query';
import { Button, Modal } from 'antd';
import { FC, Fragment } from 'react';
import { GrRevert } from 'react-icons/gr';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IoCall, IoChevronForwardSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import axios from '../../config/axios';
import { colors } from '../../theme/colors';
import { UserPhoneNumber } from '../../types/phone-numbers.types';
import Loader from '../Loader';
import FormLabel from './form-label';
import { Tab, tabs } from './tabs-data';

type DeployFormProps = {
	setActiveTab: (value: Tab) => void;
	selectedPhone: string | null;
	setSelectedPhone: (value: string | null) => void;
	handleSubmit: () => void;
	createAssistantLoading: boolean;
};

const DeployForm: FC<DeployFormProps> = (props) => {
	const [modal, contextHolder] = Modal.useModal();
	// get all phone numbers
	const { data, isLoading } = useQuery<UserPhoneNumber[]>({
		queryKey: ['phone-numbers'],
		queryFn: async () => {
			const { data } = await axios.get('/phone-numbers', { withCredentials: true });

			return data.results;
		},
	});

	return (
		<Fragment>
			{contextHolder}
			<div>
				<FormLabel
					label="Connect Phone Number"
					icon={<IoCall size={20} />}
				/>

				<div className="pt-3" />

				{isLoading && (
					<div className="flex h-32 items-center justify-center">
						<Loader />
					</div>
				)}

				{data && data.length === 0 && (
					<div className="flex flex-col items-center justify-center gap-2 rounded-lg border border-dotted px-10 py-5 text-center dark:border-[#2d2b38]">
						<div>
							<IoCall size={25} />
						</div>
						<h4 className="font-semibold">You don't have any purchased phone number yet.</h4>

						<div className="text-sm text-gray-600 dark:text-gray-300">
							A phone number will cost $2.00/mo, in addition to the charges for minutes spoken. You can buy one in the Phone Numbers tab
							or{' '}
							<Link
								to={'/phone-numbers'}
								className="text-primary"
								target='_blank'
							>
								click here.
							</Link>
						</div>
					</div>
				)}

				{data && data.length > 0 && (
					<div className="grid grid-cols-1 gap-4">
						{data.map((phone, index) => (
							<div
								key={index}
								className="flex items-center justify-between rounded-xl border px-3 py-2 dark:border-[#2d2b38]"
								role="button"
								onClick={() => {
									if (!phone.ai_assistant || props.selectedPhone === phone.phone_number_id) {
										props.setSelectedPhone(phone.phone_number_id);
									} else {
										modal.confirm({
											title: 'Phone Number is already in use by another AI Assistant',
											content: (
												<div>
													<p>
														Adding this phone number to this AI Assistant will remove it from the AI Assistant it is
														currently assigned to. Are you sure you want to proceed?
													</p>
												</div>
											),
											centered: true,
											okButtonProps: { type: 'primary', danger: true },
											okText: 'Yes, I understand',
											onOk: () => {
												props.setSelectedPhone(phone.phone_number_id);
											},
										});
									}
								}}
							>
								<div>
									<div className="font-semibold">{phone.friendly_name}</div>
									<div className="text-sm text-gray-500">
										{phone.additional_data.locality}, {phone.additional_data.region} {phone.additional_data.iso_country}
									</div>
								</div>

								{phone.phone_number_id === props.selectedPhone ? (
									<div>
										<IoIosCheckmarkCircle
											color={colors.primary}
											size={20}
										/>
									</div>
								) : null}
							</div>
						))}
					</div>
				)}
			</div>

			<div className="pt-6" />

			<div className="grid grid-cols-2 gap-5">
				<Button
					size="large"
					type="dashed"
					block
					icon={<GrRevert />}
					onClick={() => {
						props.setActiveTab(tabs[3]);
					}}
				>
					Back
				</Button>

				<Button
					size="large"
					type="primary"
					block
					icon={<IoChevronForwardSharp />}
					iconPosition="end"
					onClick={props.handleSubmit}
					loading={props.createAssistantLoading}
					disabled={props.createAssistantLoading}
				>
					Finish
				</Button>
			</div>
		</Fragment>
	);
};

export default DeployForm;
