import { Modal } from 'antd';
import { FC } from 'react';
import { AIAssistant } from '../../types/ai-assistant.types';
import MakeOutboundCall from './make-outbound-call';

type Props = {
	open: boolean;
	close: () => void;
	from_number: string;
	variables: AIAssistant['variables'];
};

const OutboundCallModal: FC<Props> = (props) => {
	return (
		<Modal
			open={props.open}
			onCancel={props.close}
			footer={null}
			centered
			destroyOnClose
		>
			<MakeOutboundCall
				from_number={props.from_number}
				close={props.close}
				variables={props.variables}
			/>
		</Modal>
	);
};

export default OutboundCallModal;
