export const us_states = [
	{
		name: 'Alabama',
		code: 'AL',
	},
	{
		name: 'Alaska',
		code: 'AK',
	},
	{
		name: 'Arizona',
		code: 'AZ',
	},
	{
		name: 'Arkansas',
		code: 'AR',
	},
	{
		name: 'California',
		code: 'CA',
	},
	{
		name: 'Colorado',
		code: 'CO',
	},
	{
		name: 'Connecticut',
		code: 'CT',
	},
	{
		name: 'Delaware',
		code: 'DE',
	},
	{
		name: 'Florida',
		code: 'FL',
	},
	{
		name: 'Georgia',
		code: 'GA',
	},
	{
		name: 'Hawaii',
		code: 'HI',
	},
	{
		name: 'Idaho',
		code: 'ID',
	},
	{
		name: 'Illinois',
		code: 'IL',
	},
	{
		name: 'Indiana',
		code: 'IN',
	},
	{
		name: 'Iowa',
		code: 'IA',
	},
	{
		name: 'Kansas',
		code: 'KS',
	},
	{
		name: 'Kentucky',
		code: 'KY',
	},
	{
		name: 'Louisiana',
		code: 'LA',
	},
	{
		name: 'Maine',
		code: 'ME',
	},
	{
		name: 'Maryland',
		code: 'MD',
	},
	{
		name: 'Massachusetts',
		code: 'MA',
	},
	{
		name: 'Michigan',
		code: 'MI',
	},
	{
		name: 'Minnesota',
		code: 'MN',
	},
	{
		name: 'Mississippi',
		code: 'MS',
	},
	{
		name: 'Missouri',
		code: 'MO',
	},
	{
		name: 'Montana',
		code: 'MT',
	},
	{
		name: 'Nebraska',
		code: 'NE',
	},
	{
		name: 'Nevada',
		code: 'NV',
	},
	{
		name: 'New Hampshire',
		code: 'NH',
	},
	{
		name: 'New Jersey',
		code: 'NJ',
	},
	{
		name: 'New Mexico',
		code: 'NM',
	},
	{
		name: 'New York',
		code: 'NY',
	},
	{
		name: 'North Carolina',
		code: 'NC',
	},
	{
		name: 'North Dakota',
		code: 'ND',
	},
	{
		name: 'Ohio',
		code: 'OH',
	},
	{
		name: 'Oklahoma',
		code: 'OK',
	},
	{
		name: 'Oregon',
		code: 'OR',
	},
	{
		name: 'Pennsylvania',
		code: 'PA',
	},
	{
		name: 'Rhode Island',
		code: 'RI',
	},
	{
		name: 'South Carolina',
		code: 'SC',
	},
	{
		name: 'South Dakota',
		code: 'SD',
	},
	{
		name: 'Tennessee',
		code: 'TN',
	},
	{
		name: 'Texas',
		code: 'TX',
	},
	{
		name: 'Utah',
		code: 'UT',
	},
	{
		name: 'Vermont',
		code: 'VT',
	},
	{
		name: 'Virginia',
		code: 'VA',
	},
	{
		name: 'Washington',
		code: 'WA',
	},
	{
		name: 'West Virginia',
		code: 'WV',
	},
	{
		name: 'Wisconsin',
		code: 'WI',
	},
	{
		name: 'Wyoming',
		code: 'WY',
	},
];

export const subscriptionPlans = [
	{
		name: 'starter',
		description: 'Ideal for AI voice assistant testing.',
		pricing: {
			monthly: 29,
			yearly: 129,
		},
		minutes: {
			monthly: '50 mins',
			yearly: '350 mins',
		},
		discountPercent: 0,
		displayFeatureTitle: 'Ideal starter plan',
		displayFeatures: ['1 Workspace', '2 Active assistant', 'ElevenLabs', 'Custom Twilio', 'Custom actions', 'REST API'],
		comparison: [
			{
				title: 'COST STRUCTURE',
				features: [
					{
						label: 'Minutes Included',
						value: {
							monthly: '50',
							yearly: '350',
						},
					},
					{
						label: 'Costs per minute*',
						value: '$0.08',
					},
				],
				helperText: '*Prices include ElevenLabs/DeepGram costs. Prices are for inbound and outbound calls.',
			},
			{
				title: 'CORE CAPABILITIES',
				features: [
					{
						label: 'Workspaces',
						value: '1',
					},
					{
						label: 'Assistants',
						value: '2',
					},
					{
						label: 'Batch campaigns',
						value: true,
					},
				],
			},
			{
				title: 'CALL SETTING',
				features: [
					{
						label: 'Custom Twilio',
						value: true,
					},
				],
			},
			{
				title: 'CUSTOM VOICE',
				features: [
					{
						label: 'ElevenLabs',
						value: true,
					},
				],
			},
			{
				title: 'ACTIONS',
				features: [
					{
						label: 'SMS',
						value: true,
					},
					{
						label: 'Real-Time Booking',
						value: true,
					},
				],
			},
			{
				title: 'INTEGRATIONS',
				features: [
					{
						label: 'Rest API',
						value: true,
					},
					{
						label: 'Calendly',
						value: true,
					},
				],
			},
			{
				title: 'SUPPORT',
				features: [
					{
						label: 'Dedicated support manager',
						value: false,
					},
					{
						label: 'Private Slack channel',
						value: false,
					},
				],
			},
		],
	},
	{
		name: 'pro',
		description: 'Teams needing top performance.',
		pricing: {
			monthly: 450,
			yearly: 1450,
		},
		minutes: {
			monthly: '2.5K mins, then 0.08$/min',
			yearly: '12.5K mins, then 0.08$/min',
		},
		discountPercent: 70,
		displayFeatureTitle: 'Everything on Starter, plus:',
		displayFeatures: [
			'1 Workspace',
			'5 Active assistant',
			'Batch campaigns (CSV/Excel)',
			'Real Time Booking',
			'Private Slack channel',
			'Workflow Builder',
		],
		comparison: [
			{
				title: 'COST STRUCTURE',
				features: [
					{
						label: 'Minutes Included',
						value: {
							monthly: '2 500',
							yearly: '12 500',
						},
					},
					{
						label: 'Costs per minute*',
						value: '$0.08',
					},
				],
				helperText: '*Prices include ElevenLabs/DeepGram costs. Prices are for inbound and outbound calls.',
			},
			{
				title: 'CORE CAPABILITIES',
				features: [
					{
						label: 'Workspaces',
						value: '1',
					},
					{
						label: 'Assistants',
						value: '5',
					},
					{
						label: 'Batch campaigns',
						value: true,
					},
				],
			},
			{
				title: 'CALL SETTING',
				features: [
					{
						label: 'Custom Twilio',
						value: true,
					},
				],
			},
			{
				title: 'CUSTOM VOICE',
				features: [
					{
						label: 'ElevenLabs',
						value: true,
					},
				],
			},
			{
				title: 'ACTIONS',
				features: [
					{
						label: 'SMS',
						value: true,
					},
					{
						label: 'Real-Time Booking',
						value: true,
					},
				],
			},
			{
				title: 'INTEGRATIONS',
				features: [
					{
						label: 'Rest API',
						value: true,
					},
					{
						label: 'Calendly',
						value: true,
					},
				],
			},
			{
				title: 'SUPPORT',
				features: [
					{
						label: 'Dedicated support manager',
						value: true,
					},
					{
						label: 'Private Slack channel',
						value: true,
					},
				],
			},
		],
	},
	{
		name: 'growth',
		description: 'Ideal for scaling AI assistants.',
		pricing: {
			monthly: 900,
			yearly: 2900,
		},
		minutes: {
			monthly: '6.5K mins, then 0.08$/min',
			yearly: '16.5K mins, then 0.08$/min',
		},
		discountPercent: 80,
		displayFeatureTitle: 'Everything on Pro, plus:',
		displayFeatures: [
			'1 Workspace',
			'1000 Active assistant',
			'White glove onboarding',
			'Dedicated success manager',
			'Reselling products',
			'Become a Solution Partner',
		],
		comparison: [
			{
				title: 'COST STRUCTURE',
				features: [
					{
						label: 'Minutes Included',
						value: {
							monthly: '6 500',
							yearly: '16 500',
						},
					},
					{
						label: 'Costs per minute*',
						value: '$0.08',
					},
				],
				helperText: '*Prices include ElevenLabs/DeepGram costs. Prices are for inbound and outbound calls.',
			},
			{
				title: 'CORE CAPABILITIES',
				features: [
					{
						label: 'Workspaces',
						value: '1',
					},
					{
						label: 'Assistants',
						value: '1000',
					},
					{
						label: 'Batch campaigns',
						value: true,
					},
				],
			},
			{
				title: 'CALL SETTING',
				features: [
					{
						label: 'Custom Twilio',
						value: true,
					},
				],
			},
			{
				title: 'CUSTOM VOICE',
				features: [
					{
						label: 'ElevenLabs',
						value: true,
					},
				],
			},
			{
				title: 'ACTIONS',
				features: [
					{
						label: 'SMS',
						value: true,
					},
					{
						label: 'Real-Time Booking',
						value: true,
					},
				],
			},
			{
				title: 'INTEGRATIONS',
				features: [
					{
						label: 'Rest API',
						value: true,
					},
					{
						label: 'Calendly',
						value: true,
					},
				],
			},
			{
				title: 'SUPPORT',
				features: [
					{
						label: 'Dedicated support manager',
						value: true,
					},
					{
						label: 'Private Slack channel',
						value: true,
					},
				],
			},
		],
	},
];

export const cardTypeToImage = (cardType: string) => {
	switch (cardType) {
		case 'visa':
			return {
				logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/1920px-Visa_2021.svg.png',
				alt: 'visa',
				background: 'linear-gradient(to right, #4877af, #1399cd)',
			};
		case 'mastercard':
			return {
				logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/1920px-Mastercard_2019_logo.svg.png',
				alt: 'mastercard',
				background: 'linear-gradient(to right, #f46b45, #eea849)',
			};
		case 'amex':
			return {
				logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/American_Express_logo.svg/1200px-American_Express_logo.svg.png',
				alt: 'visa',
				background: 'linear-gradient(to right, #28aae1, #4f4f4f)',
			};
		case 'discover':
			return {
				logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Discover_Card_logo.svg/1920px-Discover_Card_logo.svg.png',
				alt: 'visa',
				background: 'linear-gradient(to right, #ff6000, #f0a500)',
			};
		default:
			return {
				logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/1920px-Visa_2021.svg.png',
				alt: 'visa',
				background: 'linear-gradient(to right, #d4d4d4, #0ea5e9)',
			};
	}
};
