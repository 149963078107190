import { useQuery } from '@tanstack/react-query';
import WavesurferPlayer from '@wavesurfer/react';
import { Badge, Button, Card, Divider, Form, Input, Modal, Popover, Progress, Select } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { IoFilter } from 'react-icons/io5';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSearchParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import Loader from '../components/Loader';
import axios from '../config/axios';
import { cn } from '../config/cn.ts';
import dayjs from '../config/dayjs';
import AppLayout from '../layouts/app.layout';
import { useThemeStore } from '../store/theme.ts';
import { colors } from '../theme/colors';
import { AIAssistant } from '../types/ai-assistant.types.ts';
import { Voice } from '../types/voices.types.ts';
import { formatSeconds } from '../utils/helpers.ts';
import { FiPhoneIncoming, FiPhoneOutgoing } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { FaRegCopy } from 'react-icons/fa6';
import { GoCheckCircleFill } from 'react-icons/go';

export type CallLogsType = {
	call_id: string;
	from_number: string;
	to_number: string;
	assistant_id: string;
	assistant_name: string;
	voice_id: string;
	voice_name: string;
	start_time: Date;
	end_time: Date;
	call_duration_seconds: number;
	call_type: string;
	transcript: string;
	transcript_object: TranscriptObject[];
	status: string;
	provider_call_id: string;
	metadata: Metadata;
	call_recording_enabled: boolean;
	call_recording_url: string;
	call_analysis: CallAnalysis | null;
	protocol: string;
};

export type CallAnalysis = {
	summary: string;
	call_completion_summary: string;
	call_completion: string;
	task_completion_summary: string;
	task_completion: string;
};

export type Metadata = {
	model: string;
};

export type TranscriptObject = {
	role: string;
	content: string;
	name?: string;
};

const CallLogs = () => {
	const { is_dark_mode } = useThemeStore();
	const [searchParams, setSearchParams] = useSearchParams();
	const [history, setHistory] = useState<CallLogsType | undefined>();
	const [historyLoading, setHistoryLoading] = useState(true);
	const [wavesurfer, setWavesurfer] = useState<unknown>();
	const [isPlaying, setIsPlaying] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(true);
	const [logs, setLogs] = useState<CallLogsType[]>([]);
	const [count, setCount] = useState(0);
	const [wavePercent, setWavePercent] = useState(0);
	const [filterOpen, setFilterOpen] = useState<boolean>(false);
	const [filterLoading, setFilterLoading] = useState<boolean>(false);
	const [filters, setFilters] = useState<object>({});
	const [logsModalOpen, setLogsModalOpen] = useState(false);
	const { width } = useWindowSize();
	const [copied, setCopied] = useState<boolean>(false);
	const [copiedId, setCopiedId] = useState<string | null>(null);

	// get phone number from url
	const selected_call = searchParams.get('history');

	// get all call logs
	useEffect(() => {
		(async () => {
			const isFilterPresent = filters && Object.keys(filters).length > 0;
			if (isFilterPresent) {
				setFilterLoading(true);
			}

			try {
				const { data } = await axios.get('/calls', {
					withCredentials: true,
					params: {
						page,
						limit: 20,
						...filters,
					},
				});
				setCount(data.count);
				if (page === 1) {
					setLogs(data.results);
				} else {
					setLogs((prev) => [...prev, ...data.results]);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
				setFilterLoading(false);
				if (isFilterPresent) {
					setFilterOpen(false);
				}
			}
		})();
	}, [page, filters]);

	// set first call as default
	useEffect(() => {
		if (!searchParams.get('history') && logs.length > 0) {
			setSearchParams({
				history: String(logs[0].call_id),
			});
		}
	}, [logs, searchParams, setSearchParams]);

	// get call history details
	useEffect(() => {
		setHistoryLoading(true);
		setWavesurfer(undefined);
		setWavePercent(0);
		(async () => {
			if (selected_call) {
				const { data } = await axios.get(`/calls/${selected_call}`, { withCredentials: true });
				setHistory(data.results);
				setHistoryLoading(false);
			}
		})();
	}, [selected_call]);

	useEffect(() => {
		if (wavesurfer) {
			(wavesurfer as { stop: () => void }).stop();
		}
	}, [wavesurfer, selected_call]);

	// wavesurfer player
	const onReady = (ws: unknown) => {
		setWavesurfer(ws);
		setIsPlaying(false);
	};

	// play pause audio
	const onPlayPause = () => {
		if (wavesurfer) {
			(wavesurfer as { playPause: () => void }).playPause();
		}
	};

	const downloadFile = async (url: string) => {
		if (downloading) return;
		setDownloading(true);
		// Extract the last part of the URL after the last ':'
		const filename = (url.split(':').pop() || 'file') + '.mp3';

		const response = await axios.get(url, {
			responseType: 'blob', // Important
		});

		const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = urlBlob;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		link.remove();
		setDownloading(false);
	};

	const downloadTranscript = (transcript: string, call_id: string) => {
		// Create a new Blob object from transcript
		const file = new Blob([transcript], { type: 'text/plain' });

		// Create a URL for the Blob object
		const url = URL.createObjectURL(file);

		// Create a new 'a' element
		const element = document.createElement('a');
		element.href = url;

		// Set the name of the file
		element.download = `transcript-${call_id}.txt`;

		// Append the 'a' element to the body (required for Firefox)
		document.body.appendChild(element);

		// Simulate a click on the 'a' element
		element.click();

		// Remove the 'a' element from the body
		document.body.removeChild(element);
	};

	// get badge color based on status
	const getBadgeColor = (status: string) => {
		switch (status) {
			case 'incomplete':
				return 'orange';
			case 'partial':
				return 'gold';
			case 'complete':
				return 'green';
			default:
				return 'orange';
		}
	};

	// get all voices
	const { data: voices } = useQuery<Voice[]>({
		queryKey: ['voices-query'],
		queryFn: async () => {
			const { data } = await axios.get('/voices', { withCredentials: true, params: { limit: 50 } });

			return data.results;
		},
	});

	// get all ai assistants for select
	const { data: assistants } = useQuery({
		queryKey: ['ai-assistants-select'],
		queryFn: async () => {
			const { data } = await axios.get('/ai-assistants', { withCredentials: true, params: { limit: 20 } });

			const assistants = data.results.map((assistant: AIAssistant) => {
				return {
					label: assistant.name,
					value: assistant.assistant_id,
				};
			});

			return assistants;
		},
	});

	// reset copied state after 2 seconds
	useEffect(() => {
		if (copied) {
			const timeout = setTimeout(() => {
				setCopied(false);
			}, 2000);

			return () => clearTimeout(timeout);
		}
	}, [copied]);

	// filter popover
	const filterPopover = (
		<Popover
			title="Build Your Query"
			arrow={false}
			placement={width < 768 ? 'bottom' : 'bottomLeft'}
			overlayInnerStyle={{ width: 350, marginLeft: width < 768 ? 5 : 0 }}
			trigger="click"
			{...(width > 768 && {
				open: filterOpen,
				onOpenChange: (open) => setFilterOpen(open),
			})}
			content={
				<Form
					onFinish={(values) => {
						setPage(1);
						setFilters(values);
					}}
					layout="vertical"
				>
					<Form.Item
						label={<div className="font-medium">Call Type</div>}
						className="mb-2"
						name="call_type"
					>
						<Select
							options={[
								{ label: 'Incoming', value: 'incoming' },
								{
									label: 'Outgoing',
									value: 'outgoing',
								},
							]}
							placeholder="Select Call Type"
						/>
					</Form.Item>

					<Form.Item
						label={<div className="font-medium">Status</div>}
						className="mb-2"
						name="status"
					>
						<Select
							options={[
								{ label: 'Completed', value: 'completed' },
								{
									label: 'In Progress',
									value: 'in progress',
								},
								{
									label: 'Failed',
									value: 'failed',
								},
							]}
							placeholder="Select Status"
						/>
					</Form.Item>

					<Form.Item
						label={<div className="font-medium">Voice</div>}
						className="mb-2"
						name="voice_id"
					>
						<Select
							options={voices && voices.map((voice) => ({ label: voice.name, value: voice.voice_id }))}
							placeholder="Select Voice"
							virtual={false}
						/>
					</Form.Item>

					<Form.Item
						label={<div className="font-medium">AI Assistant</div>}
						className="mb-2"
						name="assistant_id"
					>
						<Select
							options={assistants || []}
							placeholder="Select AI Assistant"
							virtual={false}
						/>
					</Form.Item>

					<Form.Item
						label={<div className="font-medium">Call ID</div>}
						className="mb-2"
						name="call_id"
					>
						<Input placeholder="Enter call ID" />
					</Form.Item>

					<Form.Item
						label={<div className="font-medium">From Number</div>}
						className="mb-2"
						name="from_number"
					>
						<Input placeholder="Enter From Number" />
					</Form.Item>

					<Form.Item
						label={<div className="font-medium">To Number</div>}
						className="mb-4"
						name="to_number"
					>
						<Input placeholder="Enter To Number" />
					</Form.Item>

					<Form.Item
						className="mb-0"
						{...(width < 768 && {
							extra: <div className="pt-1 text-red-500">Close popup when loading is finished.</div>,
						})}
					>
						<div className="grid grid-cols-2 gap-2.5">
							<Button
								type="dashed"
								className="font-medium"
								htmlType="reset"
								onClick={() => {
									setPage(1);
									setFilters({});
									setFilterOpen(false);
								}}
							>
								Reset
							</Button>
							<Button
								type="primary"
								className="font-medium"
								htmlType="submit"
								loading={filterLoading}
								disabled={filterLoading}
							>
								Filter
							</Button>
						</div>
					</Form.Item>
				</Form>
			}
		>
			<Button
				icon={<IoFilter />}
				className="font-medium"
			>
				Filter
			</Button>
		</Popover>
	);

	return (
		<AppLayout
			title="Call Logs"
			subtitle="View and manage call logs here."
		>
			{loading && (
				<Card styles={{ body: { padding: 0, height: 'calc(100dvh - 8rem)' } }}>
					<div className="flex h-full items-center justify-center">
						<Loader />
					</div>
				</Card>
			)}

			{!loading && logs.length === 0 && (
				<Card styles={{ body: { padding: 0, height: 'calc(100dvh - 8rem)' } }}>
					<div className="flex h-full flex-col items-center justify-center gap-2">
						<div className="text-base font-medium text-black-3">No call logs found.</div>
						{filters && Object.keys(filters).length > 0 && (
							<div
								className="font-medium text-primary"
								role="button"
								onClick={() => {
									setPage(1);
									setFilters({});
								}}
							>
								Reset filters.
							</div>
						)}
					</div>
				</Card>
			)}

			{!loading && logs.length > 0 && (
				<div className="flex flex-col gap-5 md:flex-row">
					{/* mobile version */}
					<div className="md:hidden">
						{logs.map((log, i) => {
							if (log.call_id === searchParams.get('history')) {
								return (
									<Card
										key={i}
										styles={{ body: { padding: 10 } }}
										role="button"
										className="shadow-sm"
										onClick={() => {
											// open modal
											setLogsModalOpen(true);
										}}
									>
										<div className="flex items-center justify-between gap-2">
											<div className="flex items-center gap-2.5">
												<img
													src="/images/call-logs/call-logs.svg"
													alt="call-logs"
												/>

												<section className="flex flex-col">
													<div className="text-base font-medium capitalize text-black-7 dark:text-white">
														{['twilio', 'telnyx'].includes(log.protocol) ? 'VOIP Call' : 'Web Call'}
													</div>
													<div className="text-sm font-medium text-black-3">
														{dayjs(log.start_time).format('YYYY-MM-DD hh:mm')}
													</div>
												</section>
											</div>

											<div>
												<BsChevronDown size={20} />
											</div>
										</div>
									</Card>
								);
							}
							return null;
						})}

						{/* logs modal */}
						<Modal
							title={
								<div className="flex items-center gap-4">
									<div>Call History</div>
									<div>{filterPopover}</div>
								</div>
							}
							open={logsModalOpen}
							onCancel={() => setLogsModalOpen(false)}
							footer={null}
							centered
						>
							<div className="h-[calc(100dvh-10rem)] overflow-hidden">
								<div
									className="scrollbar-hidden h-full overflow-y-auto"
									id="log-scrollable-mobile"
								>
									<InfiniteScroll
										dataLength={logs.length}
										next={
											logs.length < count
												? () => {
														setPage((prev) => prev + 1);
													}
												: () => {}
										}
										hasMore={count > logs.length}
										style={{
											overflow: 'hidden',
										}}
										loader={
											<div className="my-3 flex items-center justify-center">
												<Loader />
											</div>
										}
										scrollThreshold={0.9}
										scrollableTarget="log-scrollable-mobile"
									>
										<div className="pt-3" />

										<div className="flex flex-col gap-3.5">
											{logs.map((log, i) => (
												<div
													key={i}
													className={cn(
														'rounded-lg border bg-white px-2 py-2.5 shadow-sm transition-all duration-300 dark:bg-[#161422]',
														{
															'border-primary': searchParams.get('history') === log.call_id,
															'dark:border-dark-border': searchParams.get('history') !== log.call_id,
														},
													)}
													onClick={() => {
														setSearchParams({
															history: log.call_id,
														});
														setLogsModalOpen(false);
													}}
													role="button"
												>
													<div className="flex items-center gap-2.5">
														<img
															src="/images/call-logs/call-logs.svg"
															alt="call-logs"
														/>

														<section className="flex flex-col">
															<div className="text-base font-medium capitalize text-black-7 dark:text-white">
																{['twilio', 'telnyx'].includes(log.protocol) ? 'VOIP Call' : 'Web Call'}
															</div>
															<div className="text-sm font-medium text-black-3">
																{dayjs(log.start_time).format('YYYY-MM-DD hh:mm')}
															</div>
														</section>
													</div>
												</div>
											))}
										</div>
									</InfiniteScroll>
								</div>
							</div>
						</Modal>
					</div>

					{/* desktop version */}
					<div className="sticky top-28 hidden h-[calc(100dvh-8rem)] w-[21rem] overflow-hidden md:block">
						<Card
							className="h-full overflow-y-auto bg-input-bg dark:bg-dark-sidebar"
							styles={{ body: { padding: 0 } }}
							id="log-scrollable"
						>
							<div className="flex items-center justify-between rounded-t-lg border-b bg-white p-3 dark:border-b-[#2d2b38] dark:bg-[#161422]">
								<div className="text-xl font-semibold leading-none text-black-7 dark:text-white">Call History</div>

								{filterPopover}
							</div>

							<InfiniteScroll
								dataLength={logs.length}
								next={
									logs.length < count
										? () => {
												setPage((prev) => prev + 1);
											}
										: () => {}
								}
								hasMore={count > logs.length}
								style={{
									overflow: 'hidden',
								}}
								loader={
									<div className="my-3 flex items-center justify-center">
										<Loader />
									</div>
								}
								scrollThreshold={0.9}
								scrollableTarget="log-scrollable"
							>
								<div className="flex flex-col gap-3.5 p-3">
									{logs.map((log: CallLogsType, i: number) => (
										<div
											key={i}
											className={cn(
												'rounded-lg border bg-white px-2 py-2.5 shadow-sm transition-all duration-300 dark:bg-[#161422]',
												{
													'border-primary': searchParams.get('history') === log.call_id,
													'dark:border-[#14121f]': searchParams.get('history') !== log.call_id,
												},
											)}
											onClick={() => {
												setSearchParams({
													history: log.call_id,
												});
											}}
											role="button"
										>
											<div className="flex items-center gap-2.5">
												<img
													src="/images/call-logs/call-logs.svg"
													alt="call-logs"
												/>

												<section className="flex flex-col">
													<div className="text-base font-medium capitalize text-black-7 dark:text-white">
														{['twilio', 'telnyx'].includes(log.protocol) ? 'VOIP Call' : 'Web Call'}
													</div>
													<div className="text-sm font-medium text-black-3">
														{dayjs(log.start_time).format('YYYY-MM-DD hh:mm')}
													</div>
												</section>
												{log.call_type === 'incoming' ? (
													<div className={'ml-auto mr-4 text-green-500'}>
														<FiPhoneIncoming size={16} />
													</div>
												) : (
													<div className={'ml-auto mr-4 text-primary'}>
														<FiPhoneOutgoing size={16} />
													</div>
												)}
											</div>
										</div>
									))}
								</div>
							</InfiniteScroll>
						</Card>
					</div>
					<div className="flex-1 pt-2">
						<div className="h-full">
							{selected_call ? (
								<Fragment>
									{historyLoading && (
										<div className="flex h-[calc(100dvh-20rem)] items-center justify-center md:h-[calc(100dvh-9rem)]">
											<Loader />
										</div>
									)}

									{!historyLoading && history && (
										<section>
											<div className="flex flex-wrap items-center justify-between gap-4">
												<section>
													<h2 className="text-xl font-bold dark:text-white">
														{['twilio', 'telnyx'].includes(history.protocol) ? 'VOIP Call' : 'Web Call'}
													</h2>

													<div className="text-sm font-medium text-black-3">
														{dayjs(history.start_time).format('YYYY-MM-DD hh:mm')}
													</div>
												</section>

												<section>
													{history.call_recording_url && (
														<div className="flex flex-wrap items-center gap-4">
															<div
																className={cn({
																	'pointer-events-none opacity-50': wavePercent < 100,
																})}
																onClick={onPlayPause}
																role="button"
																title={isPlaying ? 'Pause' : 'Play'}
															>
																{isPlaying ? (
																	<img
																		src="/images/call-logs/pause-icon.svg"
																		alt="pause-icon"
																	/>
																) : (
																	<img
																		src="/images/call-logs/play-icon.svg"
																		alt="play-icon"
																	/>
																)}
															</div>

															{wavePercent < 100 && (
																<Progress
																	percent={wavePercent}
																	style={{ width: width < 768 ? 200 : 300, marginRight: -16 }}
																	status="active"
																	strokeColor={colors.primary}
																/>
															)}

															<WavesurferPlayer
																height={40}
																width={width < 768 ? 200 : 300}
																waveColor={colors.primary}
																url={history.call_recording_url}
																onReady={onReady}
																onPlay={() => setIsPlaying(true)}
																onPause={() => setIsPlaying(false)}
																progressColor={is_dark_mode ? '#fff' : '#0f172a'}
																barWidth={2}
																barGap={1}
																barRadius={2}
																onLoading={(_, percent) => {
																	setWavePercent(percent);
																}}
															/>

															<a
																href={history.call_recording_url}
																onClick={async (e) => {
																	e.preventDefault();
																	await downloadFile(history.call_recording_url);
																}}
																aria-label="download"
																className={cn({
																	'pointer-events-none opacity-50': downloading || wavePercent < 100,
																})}
															>
																{downloading ? (
																	<div className="rounded-md border p-2">
																		<AiOutlineLoading3Quarters
																			className={'animate-spin text-primary'}
																			size={22}
																		/>
																	</div>
																) : (
																	<img
																		src={
																			is_dark_mode
																				? '/images/call-logs/download-icon-dark.svg'
																				: '/images/call-logs/download-icon.svg'
																		}
																		alt="download-icon"
																	/>
																)}
															</a>
														</div>
													)}
												</section>
											</div>

											<Divider />

											<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
												<Card styles={{ body: { padding: 16 } }}>
													<h2 className="text-xl font-semibold text-black-7 dark:text-white">Call Information</h2>

													<div className="pt-3" />

													<div className="space-y-3">
														{[
															{
																label: 'Call ID',
																value: history.call_id,
															},
															{
																label: 'Agent ID',
																value: history.assistant_id,
															},
															{
																label: 'Assistant Name',
																value: history.assistant_name,
															},

															{
																label: 'Voice Name',
																value: history.voice_name,
															},
															{
																...(history.from_number && {
																	label: 'From',
																	value: history.from_number,
																}),
															},
															{
																...(history.to_number && {
																	label: 'To',
																	value: history.to_number,
																}),
															},
														].map((item, i) => (
															<div
																key={i}
																className="space-y-0.5"
															>
																<div className="text-base font-medium text-black-7 dark:text-white">{item.label}</div>
																<div className="flex items-center gap-2">
																	<div className="text-sm font-medium text-black-3">{item.value}</div>
																	{['Call ID', 'Agent ID', 'From', 'To'].includes(item.label as string) ? (
																		<div
																			onClick={() => {
																				navigator.clipboard.writeText(item.value as string);
																				setCopied(true);
																				setCopiedId(item.value as string);
																				toast.success('Copied to clipboard.');
																			}}
																			role="button"
																		>
																			<div className="text-sm text-gray-500">
																				{copied && copiedId === item.value ? (
																					<GoCheckCircleFill color="green" />
																				) : (
																					<FaRegCopy />
																				)}
																			</div>
																		</div>
																	) : null}
																</div>
															</div>
														))}
													</div>
												</Card>

												<Card styles={{ body: { padding: 16 } }}>
													<h2 className="text-xl font-semibold text-black-7 dark:text-white">Call Duration</h2>

													<div className="pt-3" />

													<div className="space-y-3">
														{[
															{
																label: 'Call Type',
																value: history.call_type,
															},
															{
																label: 'Start Time',
																value: dayjs(history.start_time).format('YYYY-MM-DD hh:mm:ss'),
															},
															{
																label: 'End Time',
																value: history.end_time
																	? dayjs(history.end_time).format('YYYY-MM-DD hh:mm:ss')
																	: 'N/A',
															},
															{
																label: 'Duration',
																value: formatSeconds(history.call_duration_seconds ?? 0),
															},
															{
																label: 'Recorded',
																value: history.call_recording_url ? 'Yes' : 'No',
															},
														].map((item, i) => (
															<div
																key={i}
																className="space-y-0.5"
															>
																<div className="text-base font-medium text-black-7 dark:text-white">{item.label}</div>
																<div
																	className={cn('text-sm font-medium text-black-3', {
																		capitalize: item.label === 'Call Type',
																	})}
																>
																	{item.value}
																</div>
															</div>
														))}
													</div>
												</Card>
											</div>

											<div
												className={cn('pt-6', {
													'pt-0': !history.call_analysis,
												})}
											/>

											{history.call_analysis && (
												<Card styles={{ body: { padding: 16 } }}>
													<h2 className="text-xl font-semibold text-black-7 dark:text-white">Call Analysis</h2>

													<div className="pt-3" />

													<div className="space-y-3">
														<div className="space-y-0.5">
															<div className="text-base font-medium text-black-7 dark:text-white">Call Completion</div>

															<Badge
																color={getBadgeColor(history.call_analysis.call_completion)}
																text={history.call_analysis.call_completion}
																className={'font-medium capitalize *:!text-black-3'}
															/>
														</div>

														<div className="space-y-0.5">
															<div className="text-base font-medium text-black-7 dark:text-white">Task Completion</div>

															<Badge
																color={getBadgeColor(history.call_analysis.task_completion)}
																text={history.call_analysis.task_completion ?? 'N/A'}
																className={'font-medium capitalize *:!text-black-3'}
															/>
														</div>

														{[
															{
																label: 'Call Summary',
																value: history.call_analysis.summary,
															},
															{
																label: 'Call Completion Summary',
																value: history.call_analysis.call_completion_summary,
															},
															{
																label: 'Task Completion Summary',
																value: history.call_analysis.task_completion_summary ?? 'N/A',
															},
														].map((item, i) => (
															<div
																key={i}
																className="space-y-0.5"
															>
																<div className="text-base font-medium text-black-7 dark:text-white">{item.label}</div>
																<div className="text-sm font-medium text-black-3">{item.value}</div>
															</div>
														))}
													</div>
												</Card>
											)}

											<div className="pt-6" />

											<Card styles={{ body: { padding: 15 } }}>
												<div className="flex items-center justify-between gap-3">
													<h2 className="text-xl font-semibold text-black-7 dark:text-white">Transcript</h2>

													{history.transcript_object && history.transcript_object.length > 0 && (
														<img
															src={
																is_dark_mode
																	? '/images/call-logs/download-icon-dark.svg'
																	: '/images/call-logs/download-icon.svg'
															}
															alt="download-icon"
															onClick={() => downloadTranscript(history.transcript, history.call_id)}
															title={'Download Transcript'}
															role="button"
														/>
													)}
												</div>

												<div className="pt-3" />
												{history.transcript_object && history.transcript_object.length > 0 ? (
													<div className="space-y-3">
														{history.transcript_object.map((item, i) => (
															<div key={i}>
																{['user', 'assistant', 'function'].includes(item.role) && (
																	<>
																		{item.role === 'function' ? (
																			<div className={'text-base font-medium text-primary'}>
																				Tool Invocation: {item.name}
																			</div>
																		) : (
																			<div className="space-y-0.5">
																				<div className="text-base font-medium capitalize text-black-7 dark:text-white">
																					{item.role}
																				</div>
																				<div className="text-sm font-medium text-black-3">{item.content}</div>
																			</div>
																		)}
																	</>
																)}
															</div>
														))}
													</div>
												) : (
													<div className="text-base text-black-7 dark:text-white">No transcript found.</div>
												)}
											</Card>
										</section>
									)}

									<div className="pb-6" />
								</Fragment>
							) : (
								<Card className="flex h-full items-center justify-center">
									<div className="flex flex-col gap-2 text-center">
										<div className="text-base font-medium text-gray-600 dark:text-gray-300">Select a log to view details.</div>
									</div>
								</Card>
							)}
						</div>
					</div>
				</div>
			)}
		</AppLayout>
	);
};

export default CallLogs;
